import { Firebase } from "../../App";

// TODO: investigate proper handling of setupProxy vs ApiService vs firebase.json
const API_BASE = {
  WEBAPI: {
    LOCAL: "/webapi",
    TESTING: "https://europe-west1-myplayertest.cloudfunctions.net/webapi",
    LIVE: "https://europe-west1-myplayerbase.cloudfunctions.net/webapi",
  },
  GAAPI: {
    LOCAL: "/ga4",
    TESTING: "https://europe-west1-myplayertest.cloudfunctions.net/ga4",
    LIVE: "https://europe-west1-myplayerbase.cloudfunctions.net/ga4",
  },
  CREATEAPI: {
    LOCAL: "/createApi",
    TESTING:
      "https://us-central1-myplayertest.cloudfunctions.net/createApi/createApi",
    LIVE: "https://us-central1-myplayerbase.cloudfunctions.net/createApi/createApi",
  },
  CCV2: {
    LOCAL: "/ccV2",
    TESTING: "https://europe-west1-myplayertest.cloudfunctions.net/ccV2",
    LIVE: "https://europe-west1-myplayerbase.cloudfunctions.net/ccV2",
  },
};

const getApiBase = (base) =>
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API // localhost will use proxy, refer to setupProxy.js
      ? base.LOCAL // localhost + LOCAL_API = use local cloud functions
      : process.env.REACT_APP_DEMO_MODE
        ? base.TESTING // localhost + DEMO_MODE = use testing cloud functions
        : base.LIVE // localhost + no DEMO_MODE = use live cloud functions
    : process.env.REACT_APP_DEMO_MODE
      ? base.TESTING // not localhost
      : base.LIVE;

export const WEBAPI_BASE = getApiBase(API_BASE.WEBAPI);
export const GAAPI_BASE = getApiBase(API_BASE.GAAPI);
export const CREATEAPI_BASE = getApiBase(API_BASE.CREATEAPI);
export const CCV2_BASE = getApiBase(API_BASE.CCV2);

const API_HEADERS = {
  Authorization: "Bearer 1fe-PcX6FRvJXthX6bpTDn5Np/rVZyw",
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const API_PATHS = {
  DEVICE_HEARTBEAT: "/project/device/heartbeat/send",
  DEVICE_UPDATE: "/project/device/update",
  DEVICE_DECOMMISSION: "/project/device/decommission",
  DEVICE_UPDATE_STORE: "/project/device/updateStore",
  DEVICE_UPDATE_EXTRA: "/project/device/updateExtra",
  DEVICE_UPDATE_INFO: "/project/device/updateInfo",
  DEVICE_ADD_TAGS: "/project/device/addTags",

  DEVICE_INSTALLER_UPDATE: "/inst/device/update",

  PROJECT_DEVICE_COMPLIANCE_CHECK: "/project/device/deployComplianceCheck",
  PROJECT_DEVICE_DEPLOY: "/project/device/deploy",
  PROJECT_DEVICE_REDEPLOY: "/project/device/redeploy",
  PROJECT_MEDIA_CHANGED: "/project/media/changed",
  PROJECT_PREVIEW: "/project/preview",
  PROJECT_UPDATE_INFO: "/project/updateInfo",
  PROJECT_UPDATE_EXTRA: "/project/updateExtra",
  PROJECT_REFRESH_DEVICES_ONLINE_STATES: "/project/refreshDevicesOnlineStates",
  PROJECT_UPDATE_RESOLUTION: "/project/updateResolution",
  PROJECT_UPDATE_ANALYTICS_URL: "/project/updateAnalyticsUrl",
  PROJECT_UPDATE_APPS: "/project/updateApps",
  PROJECT_ADD_TASKS: "/project/addTasks",
  PROJECT_REMOVE_TASK: "/project/removeTask",
  PROJECT_MOVE_TASK: "/project/moveTask",
  PROJECT_UPDATE_TRACKING: "/project/updateTracking",

  PROJECT_PRECREATE: "/project/precreate",
  PROJECT_CREATE: "/project/create",
  PROJECT_JOIN: "/project/join",
  PROJECT_JOIN_APPROVE: "/project/join/approve",
  PROJECT_JOIN_REJECT: "/project/join/reject",
  PROJECT_JOIN_AWAITING_APPROVAL: "/project/join/awaitingApproval",
  PROJECT_LEAVE: "/project/leave",
  PROJECT_APPROVE: "/project/approve",
  PROJECT_TRANSFER: "/project/transfer",

  PROJECT_USERS_LIST: "/project/users",

  PROJECTS_LIST: "/projects/list",

  PROJECT_MEDIA_DRAFT_CREATE: "/project/media/draft/create",
  PROJECT_MEDIA_DRAFT_DELETE: "/project/media/draft/delete",
  PROJECT_MEDIA_DRAFT_PUBLISH: "/project/media/draft/publish",
  PROJECT_MEDIA_DRAFT_APPROVE: "/project/media/draft/approve",
  PROJECT_MEDIA_DRAFT_UPDATE_NAME: "/project/media/draft/updateName",
  PROJECT_MEDIA_DRAFT_UPDATE_STORAGE_INFO:
    "/project/media/draft/updateStorageInfo",
  PROJECT_MEDIA_DRAFT_GET_STORAGE_INFO:
    "/project/media/draft/getStorageInfo",

  PROJECT_PUBLIC_INFO_UPDATE_FAVORITE_CHAPTERS:
    "/project/publicInfo/updateFavoriteChapters",
  PROJECT_PUBLIC_INFO_UPDATE_STICKER: "/project/publicInfo/updateSticker",

  PROJECT_SHOWROOM_LINK_CREATE: "/project/showroomLink/create",
  PROJECT_SHOWROOM_LINK_DELETE: "/project/showroomLink/delete",
  PROJECT_SHOWROOM_LINK_UPDATE: "/project/showroomLink/update",
  PROJECT_SHOWROOM_LINK_GET: "/project/showroomLink/get",
  PROJECT_SHOWROOM_LINK_VISITS_UPDATE: "/project/showroomLink/visits/update",
  PROJECT_DEVICE_CREATE_HEARTBEAT: "/project/device/heartbeat/create",
  PROJECT_DEVICE_IS_REGISTERED: "/project/device/isRegistered",
  PROJECT_DEVICE_CREATE: "/project/device/create",
  PROJECT_DEVICE_TRANSFER: "/project/device/transfer",

  PROJECT_CODE_GENERATE: "/project/code/create",
  PROJECT_CODE_DELETE: "/project/code/delete",

  PROJECT_USER_REMOVE: "/project/user/remove",
  PROJECT_USER_UPDATE_ROLE: "/project/user/updateRole",

  PROJECT_MESSAGE_SEND: "/project/message/send",
  PROJECT_DEVICE_MESSAGE_SEND: "/project/device/message/send",
  PROJECT_MESSAGE_DELETE: "/project/message/delete",
  PROJECT_DEVICE_MESSAGE_DELETE: "/project/device/message/delete",

  PROJECT_DEVICE_CC_COMMAND: "/project/device/cc/command",
  PROJECT_DEVICE_CC_COMMAND_V2: "/project/device/cc/commandV2",
  PROJECT_DEVICE_CC_ONLINE: "/project/device/cc/online",
  PROJECT_DEVICE_CC_COMPLIANCE: "/project/device/cc/compliance",
  PROJECT_DEVICE_CC_DEPLOY: "/project/device/cc/deploy",
  PROJECT_DEVICE_CC_TRANSFER: "/project/device/cc/transfer",
  PROJECT_DEVICE_CC_SCREENCAP: "/project/device/cc/screencap",
  PROJECT_DEVICE_CC_GET: "/project/device/cc/get",
  PROJECT_DEVICE_CC_PUT: "/project/device/cc/put",

  PROJECT_QR_SETUP_UPDATE: "/project/qrSetup/update",

  MYPLAYER_GA_TRACKING: "/v2",

  ORGANISATIONS_LIST: "/organisations/list",
  ORGANISATIONS_MY_LIST: "/organisations/my",

  ORGANISATION_CHECK_DOMAIN: "/organisation/checkDomain",
  ORGANISATION_CHECK_USER_EMAIL: "/organisation/checkUserEmail",
  ORGANISATION_PRECREATE: "/organisation/precreate",
  ORGANISATION_CREATE: "/organisation/create",
  ORGANISATION_UPDATE_INFO: "/organisation/updateInfo",
  ORGANISATION_DELETE_INFO: "/organisation/deleteInfo",
  ORGANISATION_STATS: "/organisation/stats",

  ORGANISATION_MESSAGE_SEND: "/organisation/message/send",
  ORGANISATION_MESSAGE_DELETE: "/organisation/message/delete",

  ORGANISATION_USER_UPDATE_ROLE: "/organisation/user/updateRole",
  ORGANISATION_USER_REMOVE: "/organisation/user/remove",
  ORGANISATION_USER_LEAVE: "/organisation/user/leave",
  USER_ORGANISATION_SETTINGS_UPDATE: "/user/updateOrganisationSettings",
  ORGANISATION_USERS_LIST: "/organisation/users",
  ORGANISATION_PROJECTS: "/organisation/projects",

  ORGANISATION_INVITE_PENDING: "/organisation/invite/pending",
  ORGANISATION_INVITE_ACCEPT: "/organisation/invite/accept",
  ORGANISATION_INVITE_DECLINE: "/organisation/invite/decline",
  ORGANISATION_INVITE_SEND: "/organisation/invite/send",
  ORGANISATION_INVITE_UPDATE: "/organisation/invite/update",
  ORGANISATION_INVITE_DELETE: "/organisation/invite/delete",

  ORGANISATION_PREVIEW: "/organisation/preview",
  ORGANISATION_REFRESH_DEVICES_ONLINE_STATES:
    "/organisation/refreshDevicesOnlineStates",
  PROJECT_INSTALL_PARAMS_BOOT_TASKS_ADD: "/project/installParams/bootTasks/add",
  PROJECT_INSTALL_PARAMS_BOOT_TASKS_MOVE:
    "/project/installParams/bootTasks/move",
  PROJECT_INSTALL_PARAMS_BOOT_TASKS_DELETE:
    "/project/installParams/bootTasks/delete",
  PROJECT_INSTALL_PARAMS_INSTALL_TASKS_ADD:
    "/project/installParams/installTasks/add",
  PROJECT_INSTALL_PARAMS_INSTALL_TASKS_MOVE:
    "/project/installParams/installTasks/move",
  PROJECT_INSTALL_PARAMS_INSTALL_TASKS_DELETE:
    "/project/installParams/installTasks/delete",
  PROJECT_INSTALL_PARAMS_INSTALL_APPS_SET:
    "/project/installParams/installApps/set",
  PROJECT_INSTALL_PARAMS_INSTALL_APPS_SET_VERSIONS:
    "/project/installParams/installApps/setVersions",

  DEVICE_RECOVERY_CLEAR: "/device/recovery/clear",
};

const postRequest = ({ url, data = {}, idToken }) => {
  const body = {
    ...(idToken && { idToken }),
    ...data,
  };

  return fetch(url, {
    method: "POST",
    headers: API_HEADERS,
    body: JSON.stringify(body),
  })
    .then((response) =>
      response.ok
        ? response.json()
        : Promise.reject(`Error ${response.status}: ${response.statusText}`)
    )
    .catch((err) => Promise.reject(`Error ${err}`));
};

export const postPrivateRequest = async ({ path, data = {}, urlBase = WEBAPI_BASE }) => {
  const idToken = await Firebase.auth().currentUser?.getIdToken(true);
  return postRequest({
    url: urlBase + path,
    data: Object.fromEntries(
      Object.entries(data).filter(([, v]) => typeof v !== "undefined")
    ),
    idToken,
  });
};

const postPublicRequest = ({ path, data = {} }) =>
  postRequest({
    url: CREATEAPI_BASE + path,
    data,
  });

/**
 * Devices
 */

export const installerUpdateDevice = async (data) => {
  const path = API_PATHS.DEVICE_INSTALLER_UPDATE;
  return postPrivateRequest({ path, data });
};

export const decommissionDevice = async ({
  projectId,
  deviceId,
  decommissioned = true,
}) => {
  const path = API_PATHS.DEVICE_DECOMMISSION;
  const data = {
    deviceId,
    projectId,
    decommissioned,
  };

  console.debug("decommissionDevice", path, data);
  return postPrivateRequest({ path, data });
};

export const updateDeviceStore = async ({
  projectId,
  deviceId,
  info: { storeId, country, postcode, retailer },
}) => {
  const path = API_PATHS.DEVICE_UPDATE_STORE;
  const data = {
    deviceId,
    projectId,
    ...(storeId && { storeId }),
    ...(country && { country }),
    ...(postcode && { postcode }),
    ...(retailer && { retailerName: retailer }),
  };

  console.debug("updateDeviceStore", path, data);
  return postPrivateRequest({ path, data });
};

export const updateDeviceNote = ({ projectId, deviceId, note }) =>
  updateDeviceExtra({ projectId, deviceId, data: { note } });

export const updateDeviceSim = ({ projectId, deviceId, sim }) =>
  updateDeviceExtra({ projectId, deviceId, data: { sim } });

export const updateDeviceExtra = ({ projectId, deviceId, data }) => {
  const path = API_PATHS.DEVICE_UPDATE_EXTRA;
  const req = {
    projectId,
    deviceId,
    ...data,
  };

  console.debug("updateDeviceExtra", path, req);
  return postPrivateRequest({ path, data: req });
};

export const addDeviceTags = ({ projectId, deviceId, tags }) => {
  const path = API_PATHS.DEVICE_ADD_TAGS;
  const data = {
    tags,
    deviceId,
    projectId,
  };

  console.debug("addDeviceTags", path, data);
  return postPrivateRequest({ path, data });
};

export const updateDeviceTags = ({ projectId, deviceId, tags }) => {
  const path = API_PATHS.DEVICE_UPDATE_INFO;
  const data = {
    tags,
    deviceId,
    projectId,
  };

  console.debug("updateDeviceTags", path, data);
  return postPrivateRequest({ path, data });
};

export const updateDevice = async ({ projectId, deviceId, data }) => {
  const path = API_PATHS.DEVICE_UPDATE;
  const dataMerged = {
    ...data,
    deviceId,
    projectId,
    // TODO: align all timestamp
    timestamp: Date.now() / 1000,
  };

  console.debug("updateDevice", path, dataMerged);
  return postPrivateRequest({ path, data: dataMerged });
};

export const requestHeartbeat = async ({ projectId, deviceId }) => {
  const path = API_PATHS.DEVICE_HEARTBEAT;
  const data = {
    deviceId,
    projectId,
  };

  console.debug("requestHeartbeat", path, data);
  return postPrivateRequest({ path, data });
};

export const requestComplianceCheck = async ({ projectId, deviceId }) => {
  const path = API_PATHS.PROJECT_DEVICE_COMPLIANCE_CHECK;
  const data = {
    deviceId,
    projectId,
  };

  console.debug("requestComplianceCheck", path, data);
  return postPrivateRequest({ path, data });
};

export const requestDeploy = async ({ projectId, deviceId }) => {
  const path = API_PATHS.PROJECT_DEVICE_DEPLOY;
  const data = {
    deviceId,
    projectId,
  };

  console.debug("requestDeploy", path, data);
  return postPrivateRequest({ path, data });
};

/**
 * Projects
 */

export const precreateProject = (organisationId) => {
  const path = API_PATHS.PROJECT_PRECREATE;
  console.debug("precreateProject", path);
  return postPrivateRequest({ path, data: { organisationId } });
};

export const createProject = ({
  projectId,
  name,
  brand,
  desc,
  imageURL,
  organisationId,
}) => {
  const path = API_PATHS.PROJECT_CREATE;
  const data = {
    projectId,
    name,
    brand,
    desc,
    organisationId,
    ...(imageURL && { imageURL }),
  };
  console.debug("createProject", path, data);
  return postPrivateRequest({ path, data });
};

export const joinProject = ({ projectId, joinCode }) => {
  const path = API_PATHS.PROJECT_JOIN;
  const data = {
    projectId,
    ...(joinCode && { joinCode }),
  };
  console.debug("joinProject", path, data);
  return postPrivateRequest({ path, data });
};

export const joinApproveProject = ({ projectId, targetUsers }) => {
  const path = API_PATHS.PROJECT_JOIN_APPROVE;
  const data = {
    projectId,
    targetUsers,
  };
  console.debug("joinApproveProject", path, data);
  return postPrivateRequest({ path, data });
};

export const joinRejectProject = ({ projectId, targetUsers }) => {
  const path = API_PATHS.PROJECT_JOIN_REJECT;
  const data = {
    projectId,
    targetUsers,
  };
  console.debug("joinRejectProject", path, data);
  return postPrivateRequest({ path, data });
};

export const getUsersAwaitingApprovalProject = (data) => {
  const path = API_PATHS.PROJECT_JOIN_AWAITING_APPROVAL;

  console.debug("joinAwaitingApporvalProject", path, data);
  return postPrivateRequest({ path, data });
};

export const leaveProject = (projectId) => {
  const path = API_PATHS.PROJECT_LEAVE;
  const data = {
    projectId,
  };

  console.debug("leaveProject", path, data);
  return postPrivateRequest({ path, data });
};

export const approveProject = ({ projectId, message }) => {
  const path = API_PATHS.PROJECT_APPROVE;
  const data = {
    projectId,
    message,
  };

  console.debug("approveProject", path, data);
  return postPrivateRequest({ path, data });
};

export const updateProjectInfo = ({ projectId, info }) => {
  const path = API_PATHS.PROJECT_UPDATE_INFO;
  const data = {
    projectId,
    ...info,
  };

  console.debug("updateProjectInfo", path, data);
  return postPrivateRequest({ path, data });
};

export const transferProject = ({
  projectId,
  targetOrganisationId,
  keepUsers,
}) => {
  const path = API_PATHS.PROJECT_TRANSFER;
  const data = { projectId, targetOrganisationId, keepUsers };

  console.debug("transferProject", path, data);
  return postPrivateRequest({ path, data });
};

// projects for SuperAdmin
export const getAllProjects = ({
  organisationId,
  limit,
  orderBy,
  orderDesc,
  searchValue,
  lastKey,
  belongingToOrg,
}) => {
  const path = API_PATHS.PROJECTS_LIST;
  const data = {
    organisationId,
    limit,
    orderBy,
    searchValue,
    lastKey,
    orderDesc,
    belongingToOrg,
  };
  console.debug("getAllProjects", path, data);
  return postPrivateRequest({ path, data });
};

// organisation projects
export const getProjects = ({
  userRoles,
  organisationId,
  limit,
  orderBy,
  searchValue,
  lastKey,
  orderDesc,
  organisationProjectsOnly,
  version,
}) => {
  const path = API_PATHS.ORGANISATION_PROJECTS;
  const data = {
    userRoles,
    organisationId,
    limit,
    orderBy,
    searchValue,
    lastKey,
    orderDesc,
    organisationProjectsOnly,
    version,
  };
  return postPrivateRequest({ path, data });
};

export const updateProjectHardware = ({ projectId, hardware }) =>
  updateProjectExtra({ projectId, data: { hardware } });

export const updateProjectNote = ({ projectId, note }) =>
  updateProjectExtra({ projectId, data: { note } });

export const updateProjectStatus = ({ projectId, status }) =>
  updateProjectExtra({ projectId, data: { status } });

export const updateProjectTags = ({ projectId, tags }) =>
  updateProjectExtra({ projectId, data: { tags } });

export const updateProjectExtra = ({ projectId, data }) => {
  const path = API_PATHS.PROJECT_UPDATE_EXTRA;
  const req = {
    projectId,
    ...data,
  };

  console.debug("updateProjectExtra", path, req);
  return postPrivateRequest({ path, data: req });
};

export const updateProjectTracking = ({
  projectId,
  trackingCode,
  apiSecret,
}) => {
  const path = API_PATHS.PROJECT_UPDATE_TRACKING;
  const data = {
    projectId,
    trackingCode,
    apiSecret,
  };

  console.debug("updateProjectTracking", path, data);
  return postPrivateRequest({ path, data });
};

export const refreshDevicesOnlineStates = (
  projectId,
  lastDays,
  forceRefresh
) => {
  const path = API_PATHS.PROJECT_REFRESH_DEVICES_ONLINE_STATES;
  const req = {
    projectId,
    lastDays,
    forceRefresh,
  };

  console.debug("refreshDevicesOnlineStates", path, req);
  return postPrivateRequest({ path, data: req });
};

export const updateProjectResolution = ({ projectId, resolution }) => {
  const path = API_PATHS.PROJECT_UPDATE_RESOLUTION;
  const req = {
    projectId,
    projectSizeKey: resolution,
  };

  console.debug("updateProjectResolution", path, req);
  return postPrivateRequest({ path, data: req });
};

export const updateProjectAnalyticsUrl = ({ projectId, url }) => {
  const path = API_PATHS.PROJECT_UPDATE_ANALYTICS_URL;
  const data = {
    projectId,
    ...(url && { analytics: url }),
  };

  console.debug("updateProjectAnalyticsUrl", path, data);
  return postPrivateRequest({ path, data });
};

export const updateProjectApps = ({ projectId, apps, message }) => {
  const path = API_PATHS.PROJECT_UPDATE_APPS;
  const data = {
    projectId,
    message,
    ...(apps && { pkgs: apps }),
  };

  console.debug("updateProjectApps", path, data);
  return postPrivateRequest({ path, data });
};

export const addProjectTasks = ({ projectId, tasks }) => {
  const path = API_PATHS.PROJECT_ADD_TASKS;
  const data = {
    projectId,
    tasks,
  };
  console.debug("addProjectTasks", path, data);
  return postPrivateRequest({ path, data });
};

export const removeProjectTask = ({ projectId, name, index }) => {
  const path = API_PATHS.PROJECT_REMOVE_TASK;
  const data = {
    projectId,
    name,
    index,
  };
  console.debug("removeProjectTask", path, data);
  return postPrivateRequest({ path, data });
};

export const moveProjectTask = ({ projectId, index, newIndex }) => {
  const path = API_PATHS.PROJECT_MOVE_TASK;
  const data = {
    projectId,
    index,
    newIndex,
  };
  console.debug("moveProjectTask", path, data);
  return postPrivateRequest({ path, data });
};

export const notifyProjectMediaChange = async ({ projectId, message }) => {
  const path = API_PATHS.PROJECT_MEDIA_CHANGED;
  const data = {
    projectId,
    message,
  };

  console.debug("notifyProjectMediaChange", path, data);
  return postPrivateRequest({ path, data });
};

export const previewProject = ({ projectId, code }) => {
  const path = API_PATHS.PROJECT_PREVIEW;
  const data = {
    projectId,
    shareCode: code,
  };

  console.debug("previewProject", path, data);
  return postPrivateRequest({ path, data });
};

export const transferDevice = ({ deviceId, projectId, newProjectId }) => {
  const path = API_PATHS.PROJECT_DEVICE_REDEPLOY;
  const data = {
    deviceId,
    projectId: newProjectId,
    exisitingProjectId: projectId,
  };

  console.debug("transferDevice", path, data);
  return postPrivateRequest({ path, data });
};

export const createMediaDraft = ({ projectId, draftName, name, message }) => {
  const path = API_PATHS.PROJECT_MEDIA_DRAFT_CREATE;
  const data = {
    projectId,
    draftName,
    name,
    message,
  };

  console.debug("createMediaDraft", path, data);
  return postPrivateRequest({ path, data });
};

export const deleteMediaDraft = ({ projectId, draftName, message }) => {
  const path = API_PATHS.PROJECT_MEDIA_DRAFT_DELETE;
  const data = {
    projectId,
    draftId: draftName,
    message,
  };

  console.debug("deleteMediaDraft", path, data);
  return postPrivateRequest({ path, data });
};

export const publishMediaDraft = ({ projectId, draftName, message }) => {
  const path = API_PATHS.PROJECT_MEDIA_DRAFT_PUBLISH;
  const data = {
    projectId,
    draftId: draftName,
    message,
  };

  console.debug("publishMediaDraft", path, data);
  return postPrivateRequest({ path, data });
};

export const approveMediaDraft = ({ projectId, draftName, message }) => {
  const path = API_PATHS.PROJECT_MEDIA_DRAFT_APPROVE;
  const data = {
    projectId,
    draftName,
    message,
  };

  console.debug("approveMediaDraft", path, data);
  return postPrivateRequest({ path, data });
};

export const updateMediaDraftName = ({ projectId, draftName, name }) => {
  const path = API_PATHS.PROJECT_MEDIA_DRAFT_UPDATE_NAME;
  const data = {
    projectId,
    draftName,
    name,
  };

  console.debug("updateMediaDraftName", path, data);
  return postPrivateRequest({ path, data });
};

export const updateMediaDraftStorageInfo = ({ projectId, draftId }) => {
  const path = API_PATHS.PROJECT_MEDIA_DRAFT_UPDATE_STORAGE_INFO;
  const data = {
    projectId,
    draftId,
  };

  console.debug("updateMediaDraftStorageInfo", path, data);
  return postPrivateRequest({ path, data });
};

export const getMediaDraftStorageInfo = ({ projectId, draftId }) => {
  const path = API_PATHS.PROJECT_MEDIA_DRAFT_GET_STORAGE_INFO;
  const data = {
    projectId,
    draftId,
  };

  console.debug("getMediaDraftStorageInfo", path, data);
  return postPrivateRequest({ path, data });
};

export const updateMediaDraftFavoriteChapters = async ({
  projectId,
  draftId,
  chapterId,
  gauid,
  apiToken,
}) => {
  const path = API_PATHS.PROJECT_PUBLIC_INFO_UPDATE_FAVORITE_CHAPTERS;
  const data = {
    projectId,
    draftId,
    chapterId,
    gauid,
    apiToken,
  };

  console.debug("updateMediaDraftFavoriteChapters", path, data);
  return await postPublicRequest({ path, data });
};

export const updateMediaDraftSticker = async ({
  projectId,
  draftId,
  chapterId,
  answer,
  gauid,
  apiToken,
  stickerType,
}) => {
  const path = API_PATHS.PROJECT_PUBLIC_INFO_UPDATE_STICKER;
  const data = {
    projectId,
    draftId,
    chapterId,
    answer,
    stickerType,
    gauid,
    apiToken,
  };

  console.debug("updateMediaDraftSticker", path, data);
  return await postPublicRequest({ path, data });
};

export const createShowroomLink = (data) => {
  const path = API_PATHS.PROJECT_SHOWROOM_LINK_CREATE;

  console.debug("createShowroomLink", path, data);
  return postPrivateRequest({ path, data });
};

export const deleteShowroomLink = ({ projectId, linkId }) => {
  const path = API_PATHS.PROJECT_SHOWROOM_LINK_DELETE;
  const data = {
    projectId,
    linkId,
  };

  console.debug("deleteShowroomLink", path, data);
  return postPrivateRequest({ path, data });
};

export const updateShowroomLink = (data) => {
  const path = API_PATHS.PROJECT_SHOWROOM_LINK_UPDATE;

  console.debug("updateShowroomLink", path, data);
  return postPrivateRequest({ path, data });
};

export const getShowroomLink = (data) => {
  const path = API_PATHS.PROJECT_SHOWROOM_LINK_GET;

  console.debug("updateShowroomLink", path, data);
  return postPrivateRequest({ path, data });
};

export const updateShowroomLinkVisists = (data) => {
  const path = API_PATHS.PROJECT_SHOWROOM_LINK_VISITS_UPDATE;
  console.debug("updateShowroomLinkVisists", path, data);
  return postPublicRequest({ path, data });
};

export const createQRDevice = (data) => {
  const path = API_PATHS.PROJECT_DEVICE_CREATE;
  console.debug("createQRDevice", path, data);
  return postPrivateRequest({
    path,
    data,
  });
};

export const transferQRDevice = async ({
  projectId,
  deviceId,
  newProjectId,
}) => {
  const path = API_PATHS.PROJECT_DEVICE_TRANSFER;
  const data = {
    deviceId,
    projectId,
    newProjectId,
  };

  console.debug("transferQRDevice", path, data);
  return postPrivateRequest({ path, data });
};

export const trackToMyPlayerGA = (data) => {
  const path = API_PATHS.MYPLAYER_GA_TRACKING;
  console.debug("trackToMyPlayerGA", path, data);
  return postRequest({
    url: GAAPI_BASE + path,
    data,
  });
};

export const createProjectDeviceHeartbeat = (data) => {
  const path = API_PATHS.PROJECT_DEVICE_CREATE_HEARTBEAT;
  console.debug("createProjectDeviceHeartbeat", path, data);
  return postRequest({
    url: WEBAPI_BASE + path,
    data,
  });
};

export const isProjectDeviceRegistered = (data) => {
  const path = API_PATHS.PROJECT_DEVICE_IS_REGISTERED;
  return postRequest({
    url: WEBAPI_BASE + path,
    data,
  });
};

export const generateProjectCode = ({ projectId, name, defaultRole }) => {
  const path = API_PATHS.PROJECT_CODE_GENERATE;
  const data = {
    projectId,
    name,
    defaultRole,
  };
  console.debug("generateProjectCode", path, data);
  return postPrivateRequest({ path, data });
};

export const deleteProjectCode = ({ projectId, code }) => {
  const path = API_PATHS.PROJECT_CODE_DELETE;
  const data = {
    projectId,
    code,
  };
  console.debug("deleteProjectCode", path, data);
  return postPrivateRequest({ path, data });
};

export const removeUser = ({ projectId, userId }) => {
  const path = API_PATHS.PROJECT_USER_REMOVE;
  const data = {
    projectId,
    targetUserId: userId,
  };
  console.debug("removeUser", path, data);
  return postPrivateRequest({ path, data });
};

export const updateUserRole = ({ projectId, userId, role }) => {
  const path = API_PATHS.PROJECT_USER_UPDATE_ROLE;
  const data = {
    projectId,
    targetUserId: userId,
    role,
  };
  console.debug("updateUserRole", path, data);
  return postPrivateRequest({ path, data });
};

export const sendProjectMessage = ({ projectId, text, imageURL }) => {
  const path = API_PATHS.PROJECT_MESSAGE_SEND;
  const data = {
    projectId,
    ...(text && { text }),
    ...(imageURL && { imageURL }),
  };
  console.debug("sendProjectMessage", path, data);
  return postPrivateRequest({ path, data });
};

export const sendDeviceMessage = ({
  projectId,
  deviceId,
  text,
  imageURL,
  videoURL,
}) => {
  const path = API_PATHS.PROJECT_DEVICE_MESSAGE_SEND;

  const data = {
    projectId,
    deviceId,
    ...(text && { text }),
    ...(imageURL && { imageURL }),
    ...(videoURL && { videoURL }),
  };
  console.debug("sendDeviceMessage", path, data);
  return postPrivateRequest({ path, data });
};

export const sendOrganisationMessage = ({
  organisationId,
  text,
  imageURL,
  videoURL,
}) => {
  const path = API_PATHS.ORGANISATION_MESSAGE_SEND;

  const data = {
    organisationId,
    ...(text && { text }),
    ...(imageURL && { imageURL }),
    ...(videoURL && { videoURL }),
  };
  console.debug("sendOrganisationMessage", path, data);
  return postPrivateRequest({ path, data });
};

export const deleteOrganisationMessage = ({ organisationId, id }) => {
  const path = API_PATHS.ORGANISATION_MESSAGE_DELETE;
  const data = {
    organisationId,
    id,
  };
  console.debug("deleteOrganisationMessage", path, data);
  return postPrivateRequest({ path, data });
};

export const deleteProjectMessage = ({ projectId, id }) => {
  const path = API_PATHS.PROJECT_MESSAGE_DELETE;
  const data = {
    projectId,
    id,
  };
  console.debug("deleteProjectMessage", path, data);
  return postPrivateRequest({ path, data });
};

export const deleteDeviceMessage = ({ projectId, deviceId, id }) => {
  const path = API_PATHS.PROJECT_DEVICE_MESSAGE_DELETE;
  const data = {
    projectId,
    deviceId,
    id,
  };
  console.debug("deleteDeviceMessage", path, data);
  return postPrivateRequest({ path, data });
};

// cloud control

export const ccCommand = async ({
  projectId,
  deviceId,
  command,
  flags = null,
}) => {
  const path = API_PATHS.PROJECT_DEVICE_CC_COMMAND;
  const data = {
    deviceId,
    projectId,
    command,
    flags,
  };

  console.debug("ccCommand", path, data);
  return postPrivateRequest({ path, data });
};

export const ccCommandV2 = async ({
  projectId,
  deviceId,
  commandId,
  targetApplication,
  input,
  postfix,
}) => {
  const path = API_PATHS.PROJECT_DEVICE_CC_COMMAND_V2;
  const data = {
    deviceId,
    projectId,
    commandId,
    targetApplication,
    input,
    postfix,
  };

  console.debug("ccCommandV2", path, data);
  return postPrivateRequest({ path, data });
};

export const ccOnline = async ({ projectId, deviceId }) => {
  const path = API_PATHS.PROJECT_DEVICE_CC_ONLINE;
  const data = {
    deviceId,
    projectId,
  };

  console.debug("ccOnline", path, data);
  return postPrivateRequest({ path, data });
};

export const ccDeploy = async ({ projectId, deviceId }) => {
  const path = API_PATHS.PROJECT_DEVICE_CC_DEPLOY;
  const data = {
    deviceId,
    projectId,
  };

  console.debug("ccDeploy", path, data);
  return postPrivateRequest({ path, data });
};

export const ccTransfer = async ({ projectId, deviceId, newProjectId }) => {
  const path = API_PATHS.PROJECT_DEVICE_CC_TRANSFER;
  const data = {
    deviceId,
    projectId,
    newProjectId,
  };

  console.debug("ccTransfer", path, data);
  return postPrivateRequest({ path, data });
};

export const ccCompliance = async ({ projectId, deviceId }) => {
  const path = API_PATHS.PROJECT_DEVICE_CC_COMPLIANCE;
  const data = {
    deviceId,
    projectId,
  };

  console.debug("ccCompliance", path, data);
  return postPrivateRequest({ path, data });
};

export const ccGet = async ({ projectId, deviceId, path }) => {
  const apiPath = API_PATHS.PROJECT_DEVICE_CC_GET;
  const data = {
    deviceId,
    projectId,
    path,
  };

  console.debug("ccGet", apiPath, data);
  return postPrivateRequest({ path: apiPath, data });
};

export const ccPut = async ({ projectId, deviceId, path }) => {
  const apiPath = API_PATHS.PROJECT_DEVICE_CC_PUT;
  const data = {
    deviceId,
    projectId,
    path,
  };

  console.debug("ccGet", apiPath, data);
  return postPrivateRequest({ path: apiPath, data });
};

export const ccScreencap = async ({ projectId, deviceId }) => {
  const path = API_PATHS.PROJECT_DEVICE_CC_SCREENCAP;
  const data = {
    deviceId,
    projectId,
  };

  console.debug("ccScreencap", path, data);
  return postPrivateRequest({ path, data });
};

export const ccUpload = async ({ projectId, deviceId, file }) => {
  const path = "/upload";
  const data = {
    deviceId,
    projectId,
    file,
  };

  console.debug("ccUpload", path, data);
  const url = "/cc" + path;
  const idToken = await Firebase.auth().currentUser.getIdToken(true);
  const body = {
    idToken,
    ...data,
  };
  return fetch(url, {
    method: "POST",
    headers: API_HEADERS,
    body: JSON.stringify(body),
  }).then((response) =>
    response.ok
      ? response.json()
      : Promise.reject(`Error ${response.status}: ${response.statusText}`)
  );
};

export const updateProjectQRSetup = (data) => {
  const path = API_PATHS.PROJECT_QR_SETUP_UPDATE;

  console.debug("updateProjectQRSetup", path, data);
  return postPrivateRequest({ path, data });
};

export const projectInstallParamsBootTasksAdd = async ({
  projectId,
  commandId,
  targetApplication,
  input,
}) => {
  const path = API_PATHS.PROJECT_INSTALL_PARAMS_BOOT_TASKS_ADD;
  const data = {
    projectId,
    commandId,
    targetApplication,
    input,
  };

  console.debug("projectInstallParamsBootTasksAdd", path, data);
  return postPrivateRequest({ path, data });
};

export const projectInstallParamsBootTasksMove = ({
  projectId,
  index,
  newIndex,
}) => {
  const path = API_PATHS.PROJECT_INSTALL_PARAMS_BOOT_TASKS_MOVE;
  const data = {
    projectId,
    index,
    newIndex,
  };
  console.debug("projectInstallParamsBootTasksMove", path, data);
  return postPrivateRequest({ path, data });
};

export const projectInstallParamsBootTasksDelete = ({
  projectId,
  index,
  commandId,
}) => {
  const path = API_PATHS.PROJECT_INSTALL_PARAMS_BOOT_TASKS_DELETE;
  const data = {
    projectId,
    index,
    commandId,
  };
  console.debug("projectInstallParamsBootTasksDelete", path, data);
  return postPrivateRequest({ path, data });
};

export const projectInstallParamsInstallTasksAdd = async ({
  projectId,
  commandId,
  targetApplication,
  input,
}) => {
  const path = API_PATHS.PROJECT_INSTALL_PARAMS_INSTALL_TASKS_ADD;
  const data = {
    projectId,
    commandId,
    targetApplication,
    input,
  };

  console.debug("projectInstallParamsInstallTasksAdd", path, data);
  return postPrivateRequest({ path, data });
};

export const projectInstallParamsInstallTasksMove = ({
  projectId,
  index,
  newIndex,
}) => {
  const path = API_PATHS.PROJECT_INSTALL_PARAMS_INSTALL_TASKS_MOVE;
  const data = {
    projectId,
    index,
    newIndex,
  };
  console.debug("projectInstallParamsInstallTasksMove", path, data);
  return postPrivateRequest({ path, data });
};

export const projectInstallParamsInstallTasksDelete = ({
  projectId,
  index,
  commandId,
}) => {
  const path = API_PATHS.PROJECT_INSTALL_PARAMS_INSTALL_TASKS_DELETE;
  const data = {
    projectId,
    index,
    commandId,
  };
  console.debug("projectInstallParamsInstallTasksDelete", path, data);
  return postPrivateRequest({ path, data });
};

export const projectInstallParamsInstallAppsSet = async ({
  projectId,
  apps,
}) => {
  const path = API_PATHS.PROJECT_INSTALL_PARAMS_INSTALL_APPS_SET;
  const data = {
    projectId,
    apps,
  };

  console.debug("projectInstallParamsInstallAppsSet", path, data);
  return postPrivateRequest({ path, data });
};

export const projectInstallParamsInstallAppsSetVersions = async ({
  projectId,
  appsVersions,
}) => {
  const path = API_PATHS.PROJECT_INSTALL_PARAMS_INSTALL_APPS_SET_VERSIONS;
  const data = {
    projectId,
    appsVersions,
  };

  console.debug("projectInstallParamsInstallAppsSetVersions", path, data);
  return postPrivateRequest({ path, data });
};

export const clearDeviceReocvery = async (deviceId) => {
  const path = API_PATHS.DEVICE_RECOVERY_CLEAR;
  // b64signedDeviceId needed for /device/ endpoints
  const data = { deviceId, b64signedDeviceId: "1fe-PcX6FRvJXthX6bpTDn5Np/rVZyw" };
  console.debug("clearDeviceReocvery", path, data);
  return postPrivateRequest({ path, data });
}