import React, { useCallback, useEffect, useState } from "react";

import { MEDIA_LINKS_SORT_OPTION } from "../services/MediaService";
import {
  useUserProjectPermissions,
  useTotalProjectActiveSharedMediaLink,
  useProject,
} from "../services/ProjectService";
import { updateUrl } from "../services/UiService";

import PullUpPanel from "./PullUpPanel";
import EditAppsTab from "./tabs/EditAppsTab";
import EditAppsTabV2 from "./tabs/EditAppsTabV2";
import EditContentTab from "./tabs/EditContentTab";
import EditShowroomTab from "./tabs/EditShowroomTab";
import EditTasksTab from "./tabs/EditTasksTab";
import EditInstallTasksTab from "./tabs/EditInstallTasksTab";
import EditBootTasksTab from "./tabs/EditBootTasksTab";
import { isLoading } from "utils/uiUtils";

import { getProjectMediaEditTabRoute } from "../route";
import { ProjectStrings } from "../strings";

const URLS = ["content", "showroom", "apps", "installTasks", "bootTasks"];

const EditMediaPanel = ({ projectId, userId, open, onClose, tabName, version }) => {
  const totalLinksCount = useTotalProjectActiveSharedMediaLink({ projectId });
  const [currentTab, setCurrentTab] = useState(
    Math.max(0, URLS.indexOf(tabName))
  );
  const projectDetails = useProject(projectId);

  // search and sort for showroom
  const [showroomSearchText, setShowroomSearchText] = useState("");
  const [sortOption, setSortOption] = useState(MEDIA_LINKS_SORT_OPTION[0]);
  const [sortDesc, setSortDesc] = useState(true);
  const [useSearch, setUseSearch] = useState(false);

  // permissions control
  const { canReadShowroom, canReadContent, canWriteContent, canReadProjectMedia, canWriteProjectMedia } = useUserProjectPermissions({
    userId,
    projectId,
  });

  const showSearchAndSort = canReadShowroom && totalLinksCount > 0;

  // align with URLS above
  const tabs = [
    {
      label: "Content",
      render: (halfOpened, active) => (
        <EditContentTab projectId={projectId} canRead={canReadProjectMedia} canWrite={canWriteProjectMedia} /> // canReadProjectMedia
      ),
    },
    {
      label: "Showroom",
      render: (halfOpened, active) => (
        <EditShowroomTab
          projectId={projectId}
          canRead={canReadShowroom}
          showroomSearchText={showroomSearchText}
          sortOption={sortOption}
          sortDesc={sortDesc}
        />
      ),
    },
    {
      label: "Apps",
      render: (halfOpened, active) =>
        version >= 2 ? (
          <EditAppsTabV2 projectId={projectId} canRead={canReadContent} canWrite={canWriteContent} />
        ) : (
          <EditAppsTab projectId={projectId} canRead={canReadContent} canWrite={canWriteContent} />
        ),
    },
    ...(version >= 2 ? [
      {
        label: "Install Tasks",
        render: (halfOpened, active) => (
          <EditInstallTasksTab projectId={projectId} canRead={canReadContent} canWrite={canWriteContent} />
        ),
      },
      {
        label: "Boot Tasks",
        render: (halfOpened, active) => (
          <EditBootTasksTab projectId={projectId} canRead={canReadContent} canWrite={canWriteContent} />
        ),
      },
    ] : [
      {
        label: "Tasks",
        render: (halfOpened, active) => (
          <EditTasksTab projectId={projectId} canRead={canReadContent} canWrite={canWriteContent} />
        ),
      }
    ])
  ];

  const onClickTab = useCallback(
    (oldTab, newTab) => {
      setCurrentTab(newTab);
      setUseSearch(tabs[newTab]?.label === "Showroom");
      updateUrl(getProjectMediaEditTabRoute(projectId, URLS[newTab]));
    },
    [projectId, tabs]
  );

  useEffect(() => {
    if (open) {
      // on open
      if (tabName) {
        // if tabName is given by parent, set it as current tab
        const tab = Math.max(0, URLS.indexOf(tabName));
        setCurrentTab(tab);
        onClickTab(-1, tab);
      } else {
        // otherwise use currentTab state
        onClickTab(-1, currentTab);
      }
    } else {
      // reset tab on close
      setCurrentTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (isLoading(projectDetails)) return <></>;

  const onSearch = (value) => {
    setShowroomSearchText(value);
  };

  const sort = MEDIA_LINKS_SORT_OPTION.map((opt, index) => ({
    label: ProjectStrings[opt],
    onClick: () => {
      if (sortOption === opt) {
        setSortDesc(!sortDesc);
      }
      setSortOption(opt);
    },
    selected: opt === sortOption,
    desc: sortDesc,
  }));

  const config = {
    title: ProjectStrings.MEDIA_EDIT_TITLE,
    onClose,
    tabs,
    currentTab,
    onClickTab,
    ...(useSearch && showSearchAndSort && { onSearch }),
    ...(useSearch && showSearchAndSort && { sort }),
  };

  return <PullUpPanel open={open} config={config} />;
};

export default EditMediaPanel;
