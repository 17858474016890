import { areDictsEqual } from "utils/generalUtils";

export const isDeviceListEqual = (p1, p2) =>
  p1.projectId === p2.projectId &&
  p1.filter === p2.filter &&
  p1.live === p2.live &&
  p1.sortOption === p2.sortOption &&
  p1.sortDesc === p2.sortDesc &&
  p1.multiple === p2.multiple &&
  areDictsEqual(p1.permissions, p2.permissions) &&
  p1.v2 === p2.v2;

export const isEqual = (p1, p2) =>
  isDeviceListEqual(p1, p2) &&
  p1.default?.deviceId === p2.default?.deviceId &&
  p1.default?.tab === p2.default?.tab &&
  areDictsEqual(p1.permissions, p2.permissions) &&
  p1.multiple === p2.multiple &&
  p1.v2 === p2.v2;