import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useDeviceCompleteInfo } from "services/DeviceService";
import { isLoading } from "utils/uiUtils";
import { useUserProjectsPermissions, useProjectInfoWithId } from "services/ProjectService";
import { useCurrentUserId2, useSystemAdmin } from "services/UserService";
import { usePermissionsProject } from "services/PermissionService";
import { useOrganisationInfo, useUserAdmin } from "services/OrganisationService";
import { clearDeviceReocvery } from "services/ApiService";
import { canWriteDevices } from "utils/permissionUtils";

import DeviceTransfer from "ui/DeviceTransfer";

import { ROUTES } from "../route";
import { getProjectDeviceTabRoute } from "../route";
import { DefaultStrings } from "strings";

const Content = ({ children }) => <div style={{
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    gap: 16,
    height: "100%",
}}>{children}</div>;

const convertActionToDeviceTab = (action) =>
    action === "install" ? "transfer" : action;

export const useParams = () => {
    const matchDeviceTab = useRouteMatch(ROUTES.DEVICE_TAB);
    const matchDevice = useRouteMatch(ROUTES.DEVICE);
    const match = matchDeviceTab || matchDevice;
    const searchParams = new URLSearchParams(window.location.search);
    return {
        match: !!match,
        deviceId: match?.params?.deviceId,
        tab: match?.params?.tab,
        action: searchParams.get("action") ?? "install",
    }
}

export const getRoute = (deviceId, tab) =>
    ROUTES.DEVICE_TAB
        .replace(":deviceId", deviceId)
        .replace(":tab", tab);

export default function DevicePage() {
    const history = useHistory();
    const { deviceId, tab, action } = useParams();
    const [transferring, setTransferring] = useState(false);
    const [redirectTab, setRedirectTab] = useState();
    const [clearRecovery, setClearRecovery] = useState();
    const [content, setContent] = useState();
    const [showError, setShowError] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const withinOrganisation = tab === "my";

    const info = useDeviceCompleteInfo(deviceId);
    const projectId = info ? (info?.projectId ?? null) : info;
    const organisationId = info ? (info?.organisationId ?? null) : info;
    const hasBeenOnline = info ? true : info;
    const pendingRecovery = info ? !!info.pendingRecovery : info;

    // condition checking
    const inAProject = projectId ? true : projectId;
    const inAnOrg = organisationId ? true : organisationId;

    // info display
    const projInfo = useProjectInfoWithId(projectId);
    const orgInfo = useOrganisationInfo(organisationId);

    // permission check
    const userId = useCurrentUserId2();
    const isSuperadmin = useSystemAdmin();
    const projectPermissions = usePermissionsProject();
    const permissionKey = useUserProjectsPermissions(userId, projectId);
    const isProjAdmin = permissionKey && !!canWriteDevices(projectPermissions[permissionKey]);
    const isOrgAdmin = useUserAdmin({ userId, organisationId });
    const canReadProj = !!projInfo;
    const canReadOrg = !!orgInfo;

    // clear recovery
    useEffect(() => {
        if (!clearRecovery) return;
        clearDeviceReocvery(deviceId).then((res) => {
            console.debug("clearDeviceReocvery OK", res);
        }).catch((err) => {
            setShowError(true);
            console.error("clearDeviceReocvery FAIL", err);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, {
                variant: "error",
            });
        }).finally(() => {
            setClearRecovery(false);
        })
    }, [clearRecovery, deviceId, enqueueSnackbar]);

    // redirect
    useEffect(() => {
        if (!projectId || !deviceId || !redirectTab || clearRecovery || showError) return;
        history.push(getProjectDeviceTabRoute(projectId, deviceId, redirectTab));
    }, [clearRecovery, deviceId, history, projectId, redirectTab, showError]);

    const transferContent = useMemo(() => <DeviceTransfer
        devices={{ [deviceId]: { deviceId } }}
        projectId={projectId}
        permissions={{ canWriteDevices: true }}
        active={true}
        dismiss={false}
        withinOrganisation={withinOrganisation}
        v2={true}
    />, [deviceId, projectId, withinOrganisation]);

    const contact = (!canReadOrg && !canReadProj)
        ? "team@myplayer.io"
        : `${canReadOrg ? orgInfo.name + " - " : ""}${canReadProj ? projInfo.name : ""}`;

    const noPermissionContent = useMemo(() => <Content>
        <span>No permission to access device.</span>
        <span>Please contact admins at {contact}.</span>
    </Content>, [contact]);

    // showing the right content
    useEffect(() => {
        if (isLoading(hasBeenOnline)
            || isLoading(inAProject)
            || isLoading(inAnOrg)
            || isLoading(isSuperadmin)
            || isLoading(isOrgAdmin)
            || isLoading(isProjAdmin)
            || isLoading(pendingRecovery)
        ) return;

        if (!hasBeenOnline) {
            console.debug("DeviceInstall: device offline");
            // !hasBeenOnline (also !pendingRecovery) -> show message to power on device
            setContent(<Content>
                <span>Device is offline.</span>
                <span>Please power it on and connect it to the Internet.</span>
            </Content>);
        } else {
            if (!inAProject) {
                if (
                    isSuperadmin || // superadmin can always install
                    (inAnOrg && isOrgAdmin) || // org admin can also install
                    (!inAnOrg && !pendingRecovery) // device not in proj/org and no pending recovery = everyone
                ) {
                    console.debug(`DeviceInstall: install device, clear recovery=${pendingRecovery}`);
                    setClearRecovery(pendingRecovery);
                    setTransferring(true);
                    setContent(transferContent);
                } else {
                    console.debug("DeviceInstall: no permission");
                    setContent(noPermissionContent);
                }
            } else {
                if (
                    isSuperadmin || // superadmin can always transfer
                    (inAnOrg && isOrgAdmin) || // org admin can also transfer
                    isProjAdmin // proj admin can also transfer
                ) {
                    console.debug(`DeviceInstall: transfer device, clear recovery=${pendingRecovery}`);
                    setClearRecovery(pendingRecovery);
                    setRedirectTab(transferring ? "general?viewDeployment=1" : convertActionToDeviceTab(action));
                    setContent(<></>);
                } else {
                    console.debug("DeviceInstall: no permission");
                    setContent(noPermissionContent);
                }
            }
        }
    }, [hasBeenOnline, inAProject, inAnOrg, isOrgAdmin, isProjAdmin, isSuperadmin, noPermissionContent, pendingRecovery, transferContent, transferring, action]);

    if (!deviceId || isLoading(hasBeenOnline) || isLoading(inAProject) || isLoading(pendingRecovery) || isLoading(isProjAdmin)) return <></>;

    if (redirectTab && !showError) return <></>;

    return showError
        ? <Content>
            <span>An unexpected error has occurred.</span>
            <span>Please contact admins at {contact}.</span>
        </Content>
        : content ?? <></>;
};