import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import DeviceStoreCard from "./cards/DeviceStoreCard";
import DeviceOnlineCard from "./cards/DeviceOnlineCard";
import DeviceQRCard from "./cards/DeviceQRCard";
import DeviceComplianceCard from "./cards/DeviceComplianceCard";
import DeviceDeploymentCard from "./cards/DeviceDeploymentCard";
import DeviceSimCard from "./cards/DeviceSimCard";
import DeviceRetireCard from "./cards/DeviceRetireCard";
import DeviceHeartbeatsCard from "./cards/DeviceHeartbeatsCard";
import DeviceTagsCard from "./cards/DeviceTagsCard";
import RestrictedContent from "./RestrictedContent";
import Spinner from "./Spinner";
import { DEVICE_PLATFORMS } from "../services/DeviceService";
import { useMobileLayout } from "../hooks/uiHooks";
import { areDictsEqual } from "../utils/generalUtils";
import { isLoading } from "../utils/uiUtils";

const useStyles = makeStyles((theme) => ({
  generalCardContainer: (mobile) => ({
    flex: 1,
    marginTop: mobile ? theme.spacing(2) : 0,
    "&:first-child": {
      marginTop: 0,
    },
  }),
  generalCardContainerRow: (mobile) => ({
    marginLeft: mobile ? 0 : theme.spacing(2),
    "&:first-child": {
      marginLeft: 0,
    },
  }),
  generalCardContainerColumn: (mobile) => ({
    marginTop: theme.spacing(2),
    "&:first-child": {
      marginTop: 0,
    },
  }),
}));

const DeviceGeneral = ({
  deviceId,
  projectId,
  platform,
  halfOpened,
  permissions,
  live,
  v2
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const isQrId = platform === DEVICE_PLATFORMS.QRID;

  if (isLoading(deviceId) || isLoading(live)) return <Spinner />;

  if (live === null) return <></>;

  const fullSize = mobile || !halfOpened;

  const storeBlock = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        margin: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: mobile ? "column" : "row",
        }}
      >
        <div
          className={`${classes.generalCardContainer} ${classes.generalCardContainerRow}`}
        >
          <DeviceStoreCard
            projectId={projectId}
            deviceId={deviceId}
            fullSize={fullSize}
            canRead={permissions?.canReadDeviceExtra}
            canEdit={permissions?.canWriteDeviceExtra}
          />
        </div>
        {fullSize && (
          <div
            className={`${classes.generalCardContainer} ${classes.generalCardContainerRow}`}
          >
            <DeviceTagsCard
              projectId={projectId}
              deviceId={deviceId}
              canRead={permissions?.canReadDeviceExtra}
              canEdit={permissions?.canWriteDeviceExtra}
            />
          </div>
        )}
      </div>
      {fullSize && (
        <div style={{ marginTop: 16 }}>
          <DeviceHeartbeatsCard
            projectId={projectId}
            deviceId={deviceId}
            canRead={permissions?.canReadDevices}
          />
        </div>
      )}
    </div>
  );

  const actionBlock = (
    <div
      style={{
        flexGrow: 1,
        width: mobile ? "100%" : "auto",
        display: "flex",
        flexDirection: "column",
        margin: 8,
      }}
    >
      <div
        className={`${classes.generalCardContainer} ${classes.generalCardContainerColumn}`}
      >
        <DeviceOnlineCard
          projectId={projectId}
          deviceId={deviceId}
          platform={platform}
          showDesc={fullSize}
          canRead={permissions?.canReadDevices}
          canEdit={permissions?.canWriteDevices && live}
          v2={v2}
        />
      </div>
      {fullSize && !isQrId && (
        <div
          className={`${classes.generalCardContainer} ${classes.generalCardContainerColumn}`}
        >
          <DeviceComplianceCard
            deviceId={deviceId}
            projectId={projectId}
            showDesc={fullSize}
            canRead={permissions?.canReadDevices}
            canEdit={permissions?.canWriteDevices && live}
            v2={v2}
          />
        </div>
      )}
      {fullSize && isQrId && (
        <div
          className={`${classes.generalCardContainer} ${classes.generalCardContainerColumn}`}
        >
          <DeviceQRCard
            deviceId={deviceId}
            platform={platform}
            canRead={permissions?.canReadDevices}
            canEdit={permissions?.canWriteDevices && live}
          />
        </div>
      )}
      {!isQrId && (
        <div
          className={`${classes.generalCardContainer} ${classes.generalCardContainerColumn}`}
        >
          <DeviceDeploymentCard
            deviceId={deviceId}
            projectId={projectId}
            showDesc={fullSize}
            canRead={permissions?.canReadDevices}
            canEdit={permissions?.canWriteDevices && live}
            v2={v2}
          />
        </div>
      )}
    </div>
  );

  const editBlock = (
    <div
      style={{
        flexGrow: 1,
        width: mobile ? "100%" : "auto",
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        margin: 8,
      }}
    >
      {!isQrId && (
        <div
          className={`${classes.generalCardContainer} ${classes.generalCardContainerRow}`}
        >
          <DeviceSimCard
            projectId={projectId}
            deviceId={deviceId}
            canRead={permissions?.canReadDeviceExtra}
            canEdit={permissions?.canWriteDeviceExtra}
          />
        </div>
      )}
      <div
        className={`${classes.generalCardContainer} ${classes.generalCardContainerRow}`}
      >
        <DeviceRetireCard
          projectId={projectId}
          deviceId={deviceId}
          canRead={permissions?.canReadDevices}
          canEdit={permissions?.canWriteDevices}
        />
      </div>
    </div>
  );

  return (
    <RestrictedContent permitted={permissions?.canReadDevices}>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexWrap: mobile ? "wrap" : "nowrap",
          overflowY: mobile ? "auto" : "hidden",
          padding: 8,
        }}
      >
        <div
          style={{
            flexGrow: mobile ? 1 : 3,
            width: mobile ? "100%" : "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {storeBlock}
          {!mobile && fullSize && editBlock}
        </div>
        {actionBlock}
        {mobile && editBlock}
      </div>
    </RestrictedContent>
  );
};

const isEqual = (props1, props2) =>
  props1?.projectId === props2?.projectId &&
  props1?.deviceId === props2?.deviceId &&
  props1?.halfOpened === props2?.halfOpened &&
  props1?.live === props2?.live &&
  areDictsEqual(props1.permissions, props2.permissions);

export default memo(DeviceGeneral, isEqual);
