import { makeStyles } from "@material-ui/core/styles";

import { navigationBarHeight, navigationBarWidth } from "ui/pageLayout/config";

export const useStyles = makeStyles({
  root: ({ size }) => ({
    height: size.height,
    display: "flex",
    flexDirection: "column",
  }),
  main: {
    display: "flex",
    flexGrow: 1,
  },
  content: ({ mobile, noPath, reserveNavBarSpace = true }) => {
    const res = {
      width: "100%",
      overflow: "hidden",
    };
    if (noPath) return res;
    if (reserveNavBarSpace) {
      if (mobile) {
        res.marginBottom = navigationBarHeight;
      } else {
        res.paddingLeft = navigationBarWidth;
      }
    }
    return res;
  },
});
