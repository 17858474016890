import React, { useState, useCallback } from "react";
import { Chip, Collapse, TextField } from "@material-ui/core";

import {
    useDefaultCommands,
    useDefaultApps,
    useDefaultCommandVariants,
    useDefaultCommand,
} from "services/TaskService";

import MySelect from "ui/MySelect";

import { getV2Command } from "services/DeviceService";
import { debounce } from "services/UiService";

import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

import { DeviceStrings } from "../strings";

const ApplicationSelect = ({ value, onChange, commandId, disabled }) => {
    const cmd = useDefaultCommand(commandId);
    const apps = useDefaultApps();

    return (
        <MySelect
            title="Application"
            value={cmd?.application || value} // use || to skip empty string
            onChange={onChange}
            options={apps}
            disabled={Boolean(cmd?.application) || disabled}
        />
    );
};

const TaskVariants = ({ id }) => {
    const variants = useDefaultCommandVariants(id);
    if (!variants) return <></>;
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <span>Supported Platforms</span>
            <div style={{ display: "flex", gap: 8 }}>
                {Object.entries(variants).map(([k, v]) => (
                    <Chip
                        // style={{ margin: 8 }}
                        key={`task-variant-${k}`}
                        label={`${v?.alias}`}
                    />
                ))}
            </div>
        </div>
    );
};

// input = firestore live data
// values = frontend form input values
const CustomInput = ({ input, values, onChange }) => {
    if (!input) return <></>;

    const onChangeInternal = (event, key) => {
        if (onChange) onChange(key, event.target.value);
    };

    return input
        .filter((i) => i.target !== "APPLICATION")
        .map(({ key, target, title }, i) => {
            switch (target) {
                case "TEXT":
                    return (
                        <TextField
                            key={`custom-input-${i}`}
                            // style={{ margin: 5 }}
                            label={`${title} (${key})`}
                            value={values?.[key] ?? ""}
                            fullWidth
                            onChange={(e) => onChangeInternal(e, key)}
                        />
                    );
                default:
                    return <div key={`custom-input-${i}`}></div>;
            }
        });
};

// onCommand = (state, loading) => {}
const CommandSelectForm = ({ deviceId, onCommand, useAdvanced = false, showHiddenTask = false }) => {
    const commands = useDefaultCommands();
    const [category, setCategory] = useState("");
    // formInput has:
    // 1) commandId
    // 2) targetApplication
    // 3) input
    const [formInput, setFormInput] = useState({});
    const [expanded, setExpanded] = useState(false);
    const [advancedInput, setAdvancedInput] = useState("");
    const [loading, setLoading] = useState(false);

    const commandTypes =
        commands &&
        Object.fromEntries(
            Object.values(commands).map((c) => [
                c.category,
                c.category.charAt(0) + c.category.slice(1).toLowerCase(),
            ])
        );

    const selectedCommands =
        commandTypes &&
        category &&
        Object.fromEntries(
            Object.entries(commands).filter(
                ([k, v]) => (showHiddenTask || !v.hidden) && v.category.toLowerCase() === category.toLowerCase()
            )
        );

    const commandId = formInput?.commandId ?? "";
    const targetApplication = formInput?.targetApplication ?? "";
    const input = formInput?.input ?? {};

    const checkCommand = (state) => {
        const { commandId, targetApplication } = state;
        // skip if not using advanced
        if (!useAdvanced) {
            if (onCommand) onCommand(state, false);
            return;
        }
        // skip if no commandId
        if (!commandId) return;
        // skip if no application
        if (category === "APPLICATION" && !targetApplication) return;

        setLoading(true);
        if (onCommand) onCommand(null, true);
        updateCommandData(state);
    }

    const updateCommandData = useCallback(debounce((state) => {
        getV2Command({ ...state, deviceId })
            .then((res) => {
                setAdvancedInput(res.command);
                if (onCommand) onCommand(state, false);
            })
            .catch(() => {
                if (onCommand) onCommand(null, false);
            })
            .finally(() => {
                setLoading(false);
            })
    }, 1000), []);

    // when change category:
    // 1) clear advanced input
    // 2) clear form input (commandId, targetApplication, input)
    const onChangeCategory = (event) => {
        setCategory(event.target.value);
        setAdvancedInput("");

        const res = {};
        setFormInput(res);
    };
    // when change application
    // 1) set targetApplication
    // 2) keep commandId
    // 3) keep input
    const onChangeApplication = (event) => {
        const targetApplication = event.target.value;
        const res = {
            ...formInput,
            targetApplication,
        };
        setFormInput(res);
        checkCommand(res);
    }
    // when change commandId
    // 1) set commandId
    // 2) clear input
    // 3) set targetApplication if command has application
    // 4) otherwise keep targetApplication
    const onChangeCommandId = (event) => {
        const commandId = event.target.value;
        const commandApplication = commands[commandId].application;
        const res = {
            ...formInput,
            commandId,
            input: {},
            ...(commandApplication && { targetApplication: commandApplication }),
        }
        setFormInput(res);
        checkCommand(res);
    };
    // when change input
    // 1) set input
    const onChangeCustomInput = (key, value) => {
        const res = {
            ...formInput,
            input: {
                ...formInput.input,
                [key]: value,
            },
        }
        setFormInput(res);
        checkCommand(res);
    };

    return <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        {commandTypes && (
            <MySelect
                title="Category"
                value={category}
                onChange={onChangeCategory}
                options={commandTypes}
                disabled={loading}
            />
        )}

        {category === "APPLICATION" && (
            <ApplicationSelect
                key={"command-application"}
                value={targetApplication}
                onChange={onChangeApplication}
                commandId={commandId}
                disabled={loading}
            />
        )}

        {selectedCommands && (
            <>
                <MySelect
                    title="Task Name"
                    value={commandId}
                    onChange={onChangeCommandId}
                    options={selectedCommands}
                    disabled={loading}
                />
            </>
        )}

        {commandId && (
            <div style={{ padding: "6px 12px", boxSizing: "border-box", display: "flex", flexDirection: "column", gap: 8 }}>
                <TaskVariants id={commandId} />
                <span>
                    Description: {commands[commandId].desc}
                </span>
                {commands?.[commandId]?.input && (
                    <CustomInput
                        input={commands[commandId].input}
                        values={input}
                        onChange={onChangeCustomInput}
                    />
                )}
            </div>
        )}

        {useAdvanced && <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                }}
                onClick={() => setExpanded(!expanded)}
            >
                <span>
                    {DeviceStrings.COMMAND_ADVANCED}
                </span>
                {expanded ? (
                    <ExpandMoreOutlinedIcon color="primary" />
                ) : (
                    <ExpandLessOutlinedIcon color="primary" />
                )}
            </div>
            <Collapse in={expanded}>
                <TextField
                    label={DeviceStrings.COMMAND_EDIT_LABEL}
                    value={advancedInput}
                    fullWidth
                    disabled
                />
            </Collapse>
        </>}
    </div >
};

export default CommandSelectForm;