import React, { useEffect, useRef, useState } from "react";
import { Paper, Tooltip } from "@material-ui/core";
import Xarrow from "react-xarrows";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import ControlPointIcon from "@material-ui/icons/ControlPoint";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import {
  useDeviceScreen,
  useDeviceScreenRefreshing,
  useDeviceOnlineRequest,
  useDeviceOnlineResponse,
  useIsDeviceV2,
  ccBack,
  ccHome,
  ccRecent,
  ccScreencap,
  ccOnline,
  ccTap,
  ccSwipe
} from "../../services/DeviceService";
import { useMobileLayout } from "../../hooks/uiHooks";
import { isUndefined } from "utils/generalUtils";
import { useRelativeTimeString } from "utils/localeUtils";

import RemotePanelInput from "../remote/RemotePanelInput";
import RemotePanelApps from "../remote/RemotePanelApps";
import RemotePanelAppsV2 from "../remote/RemotePanelApps.v2";
import RemotePanelFiles from "../remote/RemotePanelFiles";

import { DeviceStrings, DefaultStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
  },
  screenImageFrame: {
    backgroundColor: "white",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(5),
    paddingBottom: 0,
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  screenImageContainer: {
    flexGrow: 1,
    width: "100%",
    position: "relative",
    borderWidth: 2,
    borderStyle: "inset",
    boxSizing: "border-box",
    display: "flex",
  },
  button: {
    boxSizing: "border-box",
    margin: 16,
    padding: 16,
    width: 52,
    height: 52,
    borderRadius: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  },
  buttonActive: {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
  buttonDisabled: {
    cursor: "default",
    color: "rgba(0,0,0,0.3)",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonMain: {
    width: 64,
    height: 64,
    borderRadius: 32,
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.1) 0px 5px 4px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonMainDisabled: {
    cursor: "default",
    color: "rgba(0,0,0,0.3)",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  screenControlBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  screenControlPanelContainer: ({ open }) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    height: open ? "auto" : 0,
    minHeight: open ? "30%" : 0,
    width: "100%",
    backgroundColor: "#F5F5F5",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    transition: "all .2s ease",
    overflowY: "auto",
  }),
}));

const ControlPanel = ({ open, children }) => {
  const classes = useStyles({ open });
  return <div className={classes.screenControlPanelContainer}>{children}</div>;
};

const ScreenControl = ({ controls, activePanelId, onClick }) => {
  const classes = useStyles();
  const getClass = ({ main, active, disabled }) => {
    let ret = classes.button;
    if (main) {
      ret += ` ${classes.buttonMain} ${disabled ? classes.buttonMainDisabled : ""
        }`;
    } else if (active) {
      ret += ` ${classes.buttonActive}`;
    } else {
      ret += ` ${disabled ? classes.buttonDisabled : ""}`;
    }
    return ret;
  };
  const _onClick = (state) => {
    if (state.disabled) return;
    if (onClick) onClick(state);
  }
  return (
    <div className={classes.screenControlBar}>
      {controls?.map((k, i) => (
        <Tooltip title={k.disabled ? "" : k.label} key={`control-${i}`}>
          <div
            className={getClass({
              main: k.main,
              active: k.panelId && k.panelId === activePanelId,
              disabled: k.disabled,
            })}
            onClick={() => _onClick(k)}
          >
            {k.panelId && k.panelId === activePanelId ?
              <CloseOutlinedIcon fontSize="large" /> :
              <span className="material-symbols-outlined" style={{ fontSize: 36 }}>
                {k.icon}
              </span>}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

const ScreenCard = ({ projectId, deviceId, canRead, canEdit, active }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingDate, setLoadingDate] = useState(null);
  const screen = useDeviceScreen(deviceId);
  const refreshing = useDeviceScreenRefreshing(deviceId, loadingDate);
  const [imageSize, setImageSize] = useState();
  const [swipeStart, setSwipeStart] = useState();
  const [loading, setLoading] = useState();
  const [currentPanelId, setCurrentPanelId] = useState();
  const screenLastUpdated = useRelativeTimeString(screen?.timestamp);
  const [frameSize, setFrameSize] = useState({
    width: 768 + 80,
    height: 432 + 136,
  });
  const [controlStates, setcontrolStates] = useState([
    {
      icon: "arrow_back_ios_new",
      label: DeviceStrings.REMOTE_INPUT_BACK,
      ccAction: ccBack,
      refreshOnComplete: true,
    },
    {
      icon: "radio_button_unchecked",
      label: DeviceStrings.REMOTE_INPUT_HOME,
      ccAction: ccHome,
      refreshOnComplete: true,
    },
    {
      icon: "check_box_outline_blank",
      label: DeviceStrings.REMOTE_INPUT_RECENT,
      ccAction: ccRecent,
      refreshOnComplete: true,
    },
    {
      main: true,
      icon: "center_focus_strong",
      label: DeviceStrings.REMOTE_REFRESH,
      ccAction: ccScreencap,
    },
    {
      panelId: "KEYBOARD",
      icon: "keyboard",
      label: DeviceStrings.REMOTE_INPUT_LABEL,
    },
    {
      panelId: "APPS",
      icon: "apps",
      label: DeviceStrings.REMOTE_APPS,
    },
    {
      panelId: "FILES",
      icon: "upload",
      label: DeviceStrings.REMOTE_FILE_LABEL,
    }
  ]);
  const isV2 = useIsDeviceV2(deviceId);

  // online check
  const timestampRequest = useDeviceOnlineRequest(deviceId)?.timestamp;
  const timestampResponse = useDeviceOnlineResponse(deviceId)?.timestamp;
  const [shouldSendRequest, setShouldSendRequest] = useState(true);
  const [doneSendRequest, setDoneSendRequest] = useState(false);
  const requestRef = useRef();
  const responseRef = useRef();

  // drawings
  const [clickPoint, setClickPoint] = useState();
  const [swipeStartPoint, setSwipeStartPoint] = useState();
  const [swipeEndPoint, setSwipeEndPoint] = useState();

  // controls
  const [panelStates, setPanelStates] = useState({});

  const imageRef = useRef();
  const inProgress = loading || refreshing;

  const imageRatio = imageSize?.ratio;

  const mounted = useRef(true);

  const progressStart = (startTimer = true) => {
    if (!mounted.current) return;
    setLoading(true);
    if (startTimer) setLoadingDate(new Date());
  };

  const progressClear = (src) => {
    if (!mounted.current) return;
    setLoading(false);
    setSwipeStart(null);
    setClickPoint(null);
    setSwipeStartPoint(null);
    setSwipeEndPoint(null);
  };

  const onClickControl = (state) => {
    if (state.panelId) {
      // click on a panel control
      setPanelStates((s) =>
        Object.fromEntries(controlStates.filter((c) => c.panelId)
          .map((c) => [c.panelId, currentPanelId !== c.panelId && c.panelId === state.panelId]))
      );
      setCurrentPanelId(currentPanelId === state.panelId ? null : state.panelId);
    }
    if (state.ccAction) {
      // perform cc action
      sendRemoteCommand(state);
    }
  }

  const refresh = () =>
    sendRemoteCommand({
      ccAction: ccScreencap,
    })

  const sendRemoteCommand = (state) => {
    if (inProgress) return;
    progressStart();
    const { ccAction, refreshOnComplete, input } = state;
    ccAction({ projectId, deviceId, input })
      .then(() => {
        if (refreshOnComplete) refresh();
      })
      .catch((err) => {
        console.error(`Action FAIL`, err);
      })
      .finally(() => {
        if (!refreshOnComplete) {
          progressClear(state.label);
        }
      });
  };

  useEffect(() => {
    if (isUndefined(isV2)) return;
    controlStates.forEach((state, i) => {
      if (isV2 && state.ccAction) {
        if (state.initialised) return;
        state.ccAction({ projectId, deviceId, checkOnly: true }).then((res) => {
          setcontrolStates((s) => {
            s.splice(i, 1, {
              ...s[i],
              enabled: res.valid,
              initialised: true,
            })
            return s;
          });
        }).catch((err) => {
          console.error(`check action FAIL`, err);
        })
      } else {
        setcontrolStates((s) => {
          s.splice(i, 1, {
            ...s[i],
            enabled: true,
          })
          return s;
        });
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, isV2]);

  // clear progress when refreshing changes from true to false
  useEffect(() => {
    mounted.current = true;
    if (!refreshing) {
      progressClear("not refreshing");
    }
    return () => {
      mounted.current = false;
    };
  }, [refreshing]);

  useEffect(() => {
    if (!active) return;
    if (!mounted.current) return;

    const updateFrameSize = (width, height, targetRatio) => {
      const allowedImageHeight = height - 8 - 8 - 40 - 96; // 8 = margin, 40 = top padding, 96 = control bar
      const allowedImageWidth = width - 8 - 8 - 40 - 40; // 8 = margin, 40 = top/bottom padding
      const allowedRatio = allowedImageWidth / allowedImageHeight;
      if (allowedRatio > targetRatio) {
        // use height (landscape)
        setFrameSize({
          width: allowedImageHeight * targetRatio + 80,
          height: allowedImageHeight + 136,
        });
      } else {
        // use width (portrait)
        setFrameSize({
          width: allowedImageWidth + 80,
          height: allowedImageWidth / targetRatio + 136,
        });
      }
    };

    const elem = document.getElementById("screenContainer");
    const targetRatio = imageRatio || 16 / 9;
    updateFrameSize(elem.clientWidth, elem.clientHeight, targetRatio);
  }, [active, imageRatio, projectId, deviceId]);

  // this necessary for setTimeout to get the current value
  useEffect(() => {
    requestRef.current = timestampRequest;
    responseRef.current = timestampResponse;
  }, [timestampRequest, timestampResponse]);

  useEffect(() => {
    if (!mounted.current) return;
    if (deviceId?.length >= 15) {
      setDoneSendRequest(false);
      setShouldSendRequest(true);
    }
  }, [deviceId]);

  // trigger this useEffect if
  // - active changes from false to true
  // - projectId or deviceId changes
  useEffect(() => {
    if (!active) return;
    if (!shouldSendRequest) return;
    if (doneSendRequest) return;
    if (!deviceId) return;
    if (!mounted.current) return;

    setDoneSendRequest(false);
    setShouldSendRequest(false);
    // assume v2 here, DeviceService will fallback if necessary
    ccOnline({ projectId, deviceId, v2: true })
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) return;
          if (
            !responseRef.current || // no response
            responseRef.current < requestRef.current // or no updated response since last request
          ) {
            console.warn(
              "Device is offline",
              `req=${requestRef.current}, res=${responseRef.current}`
            );
            enqueueSnackbar("Device is offline", {
              variant: "warning",
            });
            setDoneSendRequest(true);
          }
        }, 5000);
      })
      .catch((err) => {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        console.warn("systemOnline", err);
      });
  }, [
    projectId,
    deviceId,
    enqueueSnackbar,
    active,
    shouldSendRequest,
    doneSendRequest,
  ]);

  useEffect(() => {
    if (!active) return;
    if (doneSendRequest) return;
    if (!mounted.current) return;
    if (
      timestampResponse >= timestampRequest // and response after request (online)
    ) {
      console.debug(
        "Device is online",
        `req=${timestampRequest}, res=${timestampResponse}`
      );
      enqueueSnackbar("Device is online", {
        variant: "success",
      });
      setDoneSendRequest(true);
    }
  }, [
    active,
    timestampRequest,
    timestampResponse,
    enqueueSnackbar,
    doneSendRequest,
  ]);

  const onLoad = ({ target: img }) => {
    setImageSize({
      width: img.naturalWidth,
      height: img.naturalHeight,
      ratio: img.naturalWidth / img.naturalHeight,
    });
  };

  // get remote xy and relative xy from page xy (event)
  // img xy = relative to <img> (may have whitespace due to scale)
  // file xy = relative to image file (not affected by scale)
  // remote xy = relative to remote screen
  // insideFile = page xy within file boundaries
  //
  // PS: mobile should have height = 100% of parent, width = 100% of self
  const getRemoteXY = (pageX, pageY) => {
    // rect = image rendered rect
    const rect = imageRef.current.getBoundingClientRect();
    // scaled ratio
    // imageSize = image original size
    // rect = rendered <img> size
    const widthRatio = imageSize.width / rect.width;
    const heightRatio = imageSize.height / rect.height;
    // pageX = X relative to left edge of entire document
    // x = x of <img>
    const x = pageX - rect.x;
    // pageY = Y relative to top edge of entire document
    // y = y of <img>
    const y = pageY - rect.y;
    let startX = 0,
      startY = 0,
      ratio = 0;
    // we need to find out the scaled actual image content area
    if (widthRatio > heightRatio) {
      // white space vertical
      // start x = 0
      startY = Math.round((rect.height - rect.width / imageSize.ratio) / 2);
      ratio = widthRatio;
    } else {
      // white space horizontal
      // start y = 0
      startX = Math.round((rect.width - rect.height * imageSize.ratio) / 2);
      ratio = heightRatio;
    }
    const fileX = Math.round((x - startX) * ratio);
    const fileY = Math.round((y - startY) * ratio);
    const remoteX = Math.round(fileX / screen?.size);
    const remoteY = Math.round(fileY / screen?.size);
    return {
      imgX: x,
      imgY: y,
      fileX,
      fileY,
      remoteX,
      remoteY,
      insideFile:
        fileX >= 0 &&
        fileY >= 0 &&
        fileX < imageSize.width &&
        fileY < imageSize.height,
    };
  };
  const onClick = (pageX, pageY) => {
    if (inProgress) return;
    const { imgX, imgY, remoteX, remoteY, insideFile } = getRemoteXY(
      pageX,
      pageY
    );

    if (!insideFile) return;

    setClickPoint([imgX, imgY]);
    sendRemoteCommand({
      ccAction: ccTap,
      refreshOnComplete: true,
      input: {
        x: remoteX,
        y: remoteY
      }
    })
  };
  const onSwipeStart = (pageX, pageY) => {
    const { imgX, imgY, remoteX, remoteY, insideFile } = getRemoteXY(
      pageX,
      pageY
    );
    if (inProgress || !insideFile) return;
    setSwipeStartPoint([imgX, imgY]);
    setSwipeStart([remoteX, remoteY, Date.now()]);
  };
  const onSwipeEnd = (pageX, pageY) => {
    if (inProgress) return;
    const { imgX, imgY, remoteX, remoteY, insideFile } = getRemoteXY(
      pageX,
      pageY
    );
    if (!insideFile || !swipeStart) {
      return progressClear("onSwipeEnd");
    }
    if (swipeStart[0] === remoteX && swipeStart[1] === remoteY) {
      setSwipeStartPoint(null);
      setSwipeStart(null);
      return onClick(pageX, pageY);
    }
    setSwipeEndPoint([imgX, imgY]);
    sendRemoteCommand({
      ccAction: ccSwipe,
      refreshOnComplete: true,
      input: {
        x1: swipeStart[0],
        y1: swipeStart[1],
        x2: remoteX,
        y2: remoteY,
        ms: Date.now() - swipeStart[2],
      }
    })
  };
  const onTouchStart = (e) => {
    // only handle single touch
    const touches = e.changedTouches;
    if (touches.length !== 1) return;

    const touch = touches.item(0);
    onSwipeStart(touch.pageX, touch.pageY);
  };
  const onTouchEnd = (e) => {
    // only handle single touch
    const touches = e.changedTouches;
    if (touches.length !== 1) return;

    const touch = touches.item(0);
    onSwipeEnd(touch.pageX, touch.pageY);
  };
  const onMouseDown = (e) => {
    onSwipeStart(e.pageX, e.pageY);
  };
  const onMouseUp = (e) => {
    onSwipeEnd(e.pageX, e.pageY);
  };

  const screencap = (
    <img
      alt="Sreen"
      style={{
        height: "100%",
        width: "100%",
        objectFit: "contain",
        opacity: inProgress ? 0.3 : 1,
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
      }}
      src={screen?.path}
      ref={imageRef}
      onLoad={onLoad}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      draggable={false}
    />
  );
  const clickIcon = (
    <ControlPointIcon
      color="primary"
      style={{
        position: "absolute",
        left: clickPoint?.[0],
        top: clickPoint?.[1],
        transform: "translate(-50%, -50%)",
        display: clickPoint && inProgress ? "block" : "none",
      }}
    />
  );
  const startDiv = (
    <div
      id="divSwipeStart"
      style={{
        position: "absolute",
        left: swipeStartPoint?.[0],
        top: swipeStartPoint?.[1],
      }}
    />
  );
  const endDiv = (
    <div
      id="divSwipeEnd"
      style={{
        position: "absolute",
        left: swipeEndPoint?.[0],
        top: swipeEndPoint?.[1],
      }}
    />
  );
  const swipeArrow = (
    <Xarrow
      start="divSwipeStart"
      end="divSwipeEnd"
      // straight will trigger two svg errors
      path={"straight"}
      color="#F78130"
    />
  );

  const controls = controlStates?.map((s) => ({
    ...s,
    disabled: inProgress | !s.enabled
  }));

  return (
    <div id="screenContainer" className={classes.screenContainer}>
      <Paper
        className={classes.screenImageFrame}
        elevation={1}
        style={{
          width: frameSize.width,
          height: frameSize.height,
        }}
      >
        <div className={classes.screenImageContainer}>
          {isV2 && <div style={{ position: "absolute", top: -30, left: 0 }}>V2</div>}
          {screenLastUpdated && <div style={{ position: "absolute", top: -30, right: 0 }}>Screen Last Updated: {screenLastUpdated}</div>}
          {screen?.path && (
            <>
              {screencap}
              {clickIcon}
              {startDiv}
              {endDiv}
              {swipeEndPoint && swipeArrow}
            </>
          )}
          <ControlPanel open={panelStates["KEYBOARD"]}>
            <RemotePanelInput
              projectId={projectId}
              deviceId={deviceId}
              disabled={inProgress}
              onStart={progressStart}
              onComplete={() => progressClear("RemotePanelInput")}
            />
          </ControlPanel>
          <ControlPanel open={panelStates["APPS"]}>
            {isV2 ?
              <RemotePanelAppsV2
                projectId={projectId}
                deviceId={deviceId}
                disabled={inProgress}
                onStart={progressStart}
                onComplete={() => progressClear("RemotePanelAppsV2")} /> :
              <RemotePanelApps
                projectId={projectId}
                deviceId={deviceId}
                disabled={inProgress}
                onStart={progressStart}
                onComplete={() => progressClear("RemotePanelApps")}
              />}
          </ControlPanel>
          <ControlPanel open={panelStates["FILES"]}>
            <RemotePanelFiles
              projectId={projectId}
              deviceId={deviceId}
              disabled={inProgress}
              onStart={progressStart}
              onComplete={() => progressClear("RemotePanelFiles")}
            />
          </ControlPanel>
        </div>
        <ScreenControl
          controls={controls}
          activePanelId={currentPanelId}
          onClick={onClickControl}
        />
      </Paper>
    </div>
  );
};

export default ScreenCard;
