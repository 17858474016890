import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import { ccCommandV2 } from "../../services/ApiService";

import ButtonCard from "../cards/ButtonCard";
import RemoteLog from "./RemoteLog";
import CommandSelectForm from "ui/CommandSelectForm";

import { DefaultStrings, DeviceStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  appsMain: {
    padding: 24,
    height: "100%",
  },
  appsContainer: {
    marginBottom: theme.spacing(2),
    minWidth: 200,
  },
  appsRow: {
    display: "flex",
    alignItems: "center",
  },
  appsDropdown: {
    flexGrow: 1,
    margin: 5,
  },
  appsClickable: {
    cursor: "pointer",
  },
  appsAdvanced: {
    padding: 5,
  },
}));

const RemotePanelAppsV2 = ({
  projectId,
  deviceId,
  disabled,
  onStart,
  onComplete,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [commandState, setCommandState] = useState({});

  const sendCommand = () => {
    onStart();
    ccCommandV2({
      ...commandState,
      projectId,
      deviceId,
    })
      .then((result) => {
        if (result.success) {
          enqueueSnackbar(DeviceStrings.REQUEST_SENT, { variant: "success" });
        } else {
          enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          console.warn(result.errors[0]);
        }
      })
      .finally(() => {
        onComplete();
      });
  };

  const onCommand = (state, loading) => {
    setLoading(loading);
    setCommandState(state);
  }

  return (
    <div className={classes.appsMain}>
      <ButtonCard
        config={{
          icon: (
            <span className="material-symbols-outlined" style={{ fontSize: 30 }}>
              apps
            </span>
          ),
          title: DeviceStrings.REMOTE_PANEL_APPS_TITLE,
          buttonLabel: DeviceStrings.REMOTE_PANEL_APPS_BUTTON_LABEL,
          desc: DeviceStrings.REMOTE_PANEL_APPS_DESC,
          fullHeight: true,
          onClick: sendCommand,
          disableButton: disabled || loading,
        }}
        canRead={true}
      >
        <div style={{
          display: "flex",
          minHeight: 250,
          maxHeight: 400,
          gap: 8,
        }}>
          <div style={{ flex: 3, overflowY: "auto", overflowX: "hidden", display: "flex", flexDirection: "column", gap: 8 }}>
            <CommandSelectForm
              onCommand={onCommand}
              deviceId={deviceId}
              useAdvanced
              showHiddenTask />
          </div>
          <div style={{ flex: 2, overflowY: "auto" }}>
            <RemoteLog deviceId={deviceId} />
          </div>
        </div>
      </ButtonCard>
    </div>
  );
};

export default RemotePanelAppsV2;
