import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { DefaultStrings, ProjectStrings, ProfileStrings } from "strings";
import { useCurrentOrganisation } from "services/UserService";
import { sessionLogout } from "services/AuthService";
import { isLoading } from "utils/uiUtils";

import { ROUTES } from "route";
import { useParams, getRoute } from "pages/DevicePage";

export const useTabs = (organisationId) => {
  const history = useHistory();
  const { deviceId } = useParams();

  const onNavigate = useCallback((tab) => {
    history.push({ pathname: getRoute(deviceId, tab), search: window.location.search });
  }, [deviceId, history]);

  if (isLoading(organisationId)) return undefined;

  return [
    ...(organisationId ? [{
      key: "MY_PROJECTS",
      label: ProjectStrings.TAB_TITLE_MY_PROJECTS,
      tab: "my",
      onClick: () => onNavigate("my"),
    }] : []),
    {
      key: "SHARED_PROJECTS",
      label: ProjectStrings.TAB_TITLE_SHARED_PROJECTS,
      tab: "shared",
      onClick: () => onNavigate("shared"),
    }
  ];
};

export const useDeviceConfig = ({ userId }) => {
  const { match, deviceId, tab } = useParams();
  const history = useHistory();

  const organisationId = useCurrentOrganisation();
  const tabs = useTabs(match && organisationId);
  const valid = match && tabs?.map((t) => t.tab).includes(tab);
  const currentTabIndex = match && tabs?.findIndex((t) => t.tab === tab);
  const firstTab = match && tabs?.[0].tab;

  useEffect(() => {
    if (!match || isLoading(valid)) return;
    if (!valid) {
      history.push({ pathname: getRoute(deviceId, firstTab), search: window.location.search });
    }
  }, [deviceId, valid, history, match, firstTab]);

  return [
    {
      path: [ROUTES.DEVICE, ROUTES.DEVICE_TAB],
      hideSidebar: true,
      appBar: {
        title: DefaultStrings.APP_NAME,
        tabDefault: tabs?.[currentTabIndex]?.key,
        tabs,
        overflow: [
          {
            label: ProfileStrings.PROFILE_LOGOUT_TITLE,
            onClick: sessionLogout,
          },
        ],
      },
    }
  ];
};
