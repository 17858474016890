import React, { useState } from "react";

import ButtonCard from "./ButtonCard";
import CommandSelectForm from "ui/CommandSelectForm";

import { ProjectStrings } from "../../strings";

const AddTasksCard = ({
  title,
  projectId,
  description,
  canRead,
  canWrite,
  onAddTask,
}) => {
  const [loading, setLoading] = useState(false);
  const [commandState, setCommandState] = useState({});

  const onAddTaskInternal = () => {
    setLoading(true);
    onAddTask({
      ...commandState,
      projectId,
    }).finally(() => {
      setLoading(false);
    });
  };

  const onCommand = (state) => {
    setCommandState(state);
  }

  const configCard = {
    icon: <span className="material-symbols-outlined">assignment_add</span>,
    title,
    desc: description,
    buttonLabel: ProjectStrings.TASKS_ADD_TASK_BUTTON,
    onClick: onAddTaskInternal,
    fullHeight: true,
    disableButton: !canWrite,
    progress: loading,
  };

  return (
    <ButtonCard config={configCard} canRead={canRead}>
      {canWrite && <CommandSelectForm onCommand={onCommand} />}
    </ButtonCard>
  );
};

export default AddTasksCard;
