import { matchPath, useLocation } from "react-router-dom";

import { ROUTES } from "route";
import { sessionLogout } from "services/AuthService";
import { useOrganisationConfig } from "./useOrganisationConfig";
import { useAdminOrganisationConfig } from "./useAdminOrganisationConfig";
import { useProjectsConfig } from "./useProjectsConfig";
import { useProjectConfig } from "./useProjectConfig";
import { useDeviceConfig } from "./useDeviceConfig";
import { DefaultStrings, NewsStrings, ProfileStrings } from "strings";

export const usePageConfig = (props) => {
  const { userId } = props;
  const location = useLocation();

  const getMatchRoute = (path) => {
    return matchPath(location.pathname, {
      path,
    });
  };

  const getOrganisationId = () => {
    const match = getMatchRoute(ROUTES.ORGANISATION_ROOT);
    if (!match) return null;

    const params = match?.params;
    if (params?.organisationId && params?.organisationId !== "join") {
      return params?.organisationId;
    }
    return null;
  };

  const organisationId = getOrganisationId();

  const projectId = getMatchRoute(ROUTES.PROJECT_ROOT)?.params?.projectId;

  // organisation config
  const organisationConfig = useOrganisationConfig({
    userId,
    organisationId,
  });
  // admin organisations config
  const adminOrgConfig = useAdminOrganisationConfig();
  // projects config
  const projectsConfig = useProjectsConfig({ userId });
  // project config
  const projectConfig = useProjectConfig({ userId, projectId });

  const pageConfig = [
    // organisation config
    ...organisationConfig,
    // admin organisations config
    ...adminOrgConfig,
    // projects config
    ...projectsConfig,
    // project config
    ...projectConfig,
    ...useDeviceConfig({ userId }),
    // profile config
    {
      path: ROUTES.PROFILE,
      appBar: {
        overflow: [
          {
            label: ProfileStrings.PROFILE_LOGOUT_TITLE,
            onClick: sessionLogout,
          },
        ],
        title: DefaultStrings.TITLE,
      },
    },
    // news config
    {
      path: ROUTES.NEWS,
      appBar: {
        title: NewsStrings.NEWS_TITLE,
      },
    },
  ];

  // match current configuration with current location pathname
  const draftConfig = pageConfig.find((pathConfig) => {
    if (Array.isArray(pathConfig)) {
      return pathConfig.find((path) => getMatchRoute(path));
    }
    return getMatchRoute(pathConfig.path);
  });

  if (draftConfig && Array.isArray(draftConfig.path)) {
    const idx = draftConfig.path.findIndex((path) => getMatchRoute(path));
    if (idx !== -1) {
      draftConfig.path = draftConfig.path[idx];
    }
  }

  const currentConfig = draftConfig;

  // return the found config
  if (currentConfig) return currentConfig;

  // if no config found, return empty
  return {};
};
