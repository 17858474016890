import React from "react";
import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout } from "../../hooks/uiHooks";
import { useProjectBootTasks } from "../../services/ProjectService";
import {
  projectInstallParamsBootTasksAdd,
  projectInstallParamsBootTasksMove,
  projectInstallParamsBootTasksDelete,
} from "../../services/ApiService";

import RestrictedContent from "../RestrictedContent";
import AddTasksCard from "../cards/AddTasksCard";
import TasksList from "../TasksList";

const useStyles = makeStyles((theme) => ({
  main: (mobile) => ({
    overflowY: "auto",
    padding: theme.spacing(mobile ? 1 : 2),
    height: "100%",
    display: "flex",
    flexDirection: mobile ? "column-reverse" : "row",
  }),
  leftContainer: (mobile) => ({
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    flexGrow: 3,
    overflowY: "auto",
    width: mobile ? "auto" : 1,
  }),
  rightContainer: (mobile) => ({
    margin: theme.spacing(1),
    flexGrow: mobile ? 0 : 2,
    width: mobile ? "auto" : 1,
  }),
}));

const EditBootTasksTab = ({ projectId, canRead, canWrite }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  // tasks is from database
  const tasks = useProjectBootTasks(projectId);

  return (
    <RestrictedContent permitted={canRead}>
      <div className={classes.main}>
        <Card className={classes.leftContainer}>
          <TasksList
            projectId={projectId}
            tasks={tasks?.items}
            canRead={canRead}
            canWrite={canWrite}
            onMove={projectInstallParamsBootTasksMove}
            onDelete={projectInstallParamsBootTasksDelete}
          />
        </Card>
        <div className={classes.rightContainer}>
          <AddTasksCard
            title="Boot Tasks"
            projectId={projectId}
            description="Add tasks to be run after system boot up."
            canRead={canRead}
            canWrite={canWrite}
            onAddTask={projectInstallParamsBootTasksAdd}
          />
        </div>
      </div>
    </RestrictedContent>
  );
};

export default EditBootTasksTab;
