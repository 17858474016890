import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";

import Sidebar from "ui/sidebar/Sidebar";
import MyAppBar from "ui/myAppBar/MyAppBar";
import { usePageConfig } from "./usePageConfig";
import { useStyles } from "./style";
import { useMobileLayout, useWindowSize } from "hooks/uiHooks";
import { UserPendingInvitesDialog, OrganisationCreateDialog } from "ui/dialogs";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";
import MainContext from "context/MainContext";

const mapStateToProps = ({
  firebase: {
    auth: { uid },
  },
}) => ({ userId: uid });

const PageLayout = ({ children, userId }) => {
  const size = useWindowSize();
  const mobile = useMobileLayout();
  const pageConfig = usePageConfig({
    userId,
  });
  const noPath = !pageConfig?.path;
  const classes = useStyles({
    mobile,
    noPath,
    size,
    reserveNavBarSpace: !pageConfig?.hideSidebar,
  });
  const { actionType, onAction } = useContext(MainContext);

  const onCompleteHandle = () =>
    onAction(GLOBAL_ACION_TYPES.ORGANISATION_CREATE, false);

  const onCloseHandle = () =>
    onAction(GLOBAL_ACION_TYPES.ORGANISATION_CREATE, false);

  if (noPath)
    return (
      <>
        {children}
        <UserPendingInvitesDialog userId={userId} />
      </>
    );

  return (
    <div className={classes.root}>
      {pageConfig?.appBar && (
        <MyAppBar config={pageConfig.appBar} path={pageConfig.path} />
      )}
      <div className={classes.main}>
        {!pageConfig?.hideSidebar && (
          <Sidebar config={pageConfig.actionButton} path={pageConfig.path} />
        )}
        <Box className={classes.content}>{children}</Box>
      </div>
      <UserPendingInvitesDialog userId={userId} />
      <OrganisationCreateDialog
        open={actionType[GLOBAL_ACION_TYPES.ORGANISATION_CREATE]}
        onComplete={onCompleteHandle}
        onClose={onCloseHandle}
      />
    </div>
  );
};

export default connect(mapStateToProps)(PageLayout);
