import { NAMES, PACKAGES } from "./appsUtils";

const PROJECT_CODE = process.env.REACT_APP_DEMO_MODE
  ? "myplayertest"
  : "myplayerbase";

// https://storage.googleapis.com/myplayerbase.appspot.com/apks/io.myplayer.android.myprojects/MyProjects-0.1.7.apk
export const getMyProjectsDownloadUrl = (ver = "0.1.7") =>
  `https://storage.googleapis.com/${PROJECT_CODE}.appspot.com/apks/${
    PACKAGES.MYPROJECTS
  }/${NAMES.MYPROJECTS}-${ver}.apk?t=${Date.now()}`;
