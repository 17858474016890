import React, { memo, useEffect, useState, useContext } from "react";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import DevicesIcon from "@material-ui/icons/Devices";

import {
  useProjectDevices,
  useTotalLiveDevicesCount,
  useTotalDevicesCount,
  DEVICE_PLATFORMS,
  ccOnline,
  ccDeploy,
  ccCompliance,
} from "services/DeviceService";
import { decommissionDevice } from "services/ApiService";
import { debounce } from "services/UiService";
import { getLinkUrl } from "services/MediaService";
import { updateQRSetup, getProjectQRSetup } from "services/ProjectService";
import { uploadFile, deleteFile } from "services/StorageService";
import { isLoading } from "utils/uiUtils";
import {
  DEVICE_SORT_OPTION_FIELD,
  DEVICE_SORT_OPTION_KEYS,
  DEVICE_DESC_SORT_OPTIONS
} from "ui/pageLayout/config";
import { useMobileLayout, useConfirm } from "hooks/uiHooks";

import { EmptyData } from "ui/emptyData";
import Spinner from "ui/Spinner";
import MyDialog from "ui/MyDialog";
import DeviceTransfer from "ui/DeviceTransfer";
import { Dropdown } from "components";

import DeviceTagsCard from "../../../cards/DeviceTagsCard";

import { DeviceStrings, DefaultStrings } from "strings";
import { List } from "../index";
import { isDeviceListEqual } from "../../utils";
import { useStyles } from "./styles";
import { buildFilterItems, filterByKey } from "./utils";
import { CustomQRCodeDialog } from "ui/dialogs";
import MainContext from "context/MainContext";

const DISPLAY_ITEMS = {
  DEFAULT: 48,
  // MAX: 240,
  MAX: 100100100,
  LOAD_MORE: 24,
  MOBILE_DIVIDER: 6,
};

// TODO: this component has grown too big and complicated and needs a refactor
const DeviceList = memo(
  ({ projectId, live, permissions, onClickDevice, multiple, v2 }) => {
    const mobile = useMobileLayout();
    const classes = useStyles(mobile);
    const { sortDescValue, sortFieldValue, searchValue } =
      useContext(MainContext);

    // params for data
    const itemDefault =
      DISPLAY_ITEMS.DEFAULT / (mobile ? DISPLAY_ITEMS.MOBILE_DIVIDER : 1);
    const [limit, setLimit] = useState(itemDefault);
    const sort =
      sortFieldValue && sortFieldValue in DEVICE_SORT_OPTION_FIELD
        ? DEVICE_SORT_OPTION_FIELD[sortFieldValue]
        : DEVICE_SORT_OPTION_FIELD[DEVICE_SORT_OPTION_KEYS[0]];
    const params = {
      limit,
      startAt: 0,
      orderBy: sort,
      orderDesc: sortFieldValue in sortDescValue ?
        DEVICE_SORT_OPTION_FIELD.SORT_OPTION_COMPLIANCE === sort ? !sortDescValue[sortFieldValue] : sortDescValue[sortFieldValue]
        : DEVICE_DESC_SORT_OPTIONS[sortFieldValue],
      filter: searchValue,
      live,
    };

    // service data
    const devices = useProjectDevices({ projectId, params });
    const allDevices = useTotalDevicesCount(projectId);
    const liveDevices = useTotalLiveDevicesCount(projectId);
    const total = live ? liveDevices : allDevices - liveDevices;

    const [selectedDevices, setSelectedDevices] = useState({});
    const [selectedDevicePlatforms, setSelectedDevicePlatforms] = useState({});

    const actionsDisabled =
      Object.entries(selectedDevices).filter(([k, v]) => v).length === 0;
    const selectedDeviceIds = Object.entries(selectedDevices).map(
      ([k, v]) => k
    );
    const deviceIds = devices.map((d) => d.deviceId);
    const canWriteDevices = permissions?.canWriteDevices;
    const canWriteDeviceExtra = permissions?.canWriteDeviceExtra;

    const [selectMultiple, setSelectMultiple] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [showTagDialog, setShowTagDialog] = useState(false);
    const [showCustomQRDialog, setShowCustomQRDialog] = useState(false);

    const [mediaQRSettings, setMediaQRSettings] = useState(null);

    const deviceTransferred = selectedDeviceIds.some(
      (deviceId) => !deviceIds.includes(deviceId)
    );

    const confirm = useConfirm();
    const { enqueueSnackbar } = useSnackbar();
    const selectedCount = selectedDeviceIds.length;

    // filter
    const [selectedCountries, setSelectedCountries] = useState([
      DeviceStrings.FILTER_ALL,
    ]);
    const [selectedRetailers, setSelectedRetailers] = useState([
      DeviceStrings.FILTER_ALL,
    ]);
    const [selectedTags, setSelectedTags] = useState([
      DeviceStrings.FILTER_ALL,
    ]);

    let devicesFiltered = devices;

    /** Countries filter */
    const countryKey = "country";
    const selectedAllCountries =
      selectedCountries.length === 1 &&
      selectedCountries[0] === DeviceStrings.FILTER_ALL;

    var countries = buildFilterItems(devices, countryKey);
    var countriesItems = [...countries.values()];

    if (!selectedAllCountries) {
      devicesFiltered = filterByKey(
        devicesFiltered,
        selectedCountries,
        countryKey
      );
    }

    const countriesJointedStr = JSON.stringify(countriesItems);
    const qrConfig = {
      downloadButtonTitle:
        DeviceStrings.CREATE_SMART_TAGS_DIALOG_DOWNLOAD_BUTTON_TITLE,
      urlTitle: DeviceStrings.CREATE_SMART_TAGS_DIALOG_URL_TITLE,
      selectedURLs: DeviceStrings.CREATE_SMART_TAGS_DIALOG_SELECTED_TITLE,
      downloadingTitle:
        DeviceStrings.CREATE_SMART_TAGS_DIALOG_DOWNLOADING_TITLE,
      downloadingDescription:
        DeviceStrings.CREATE_SMART_TAGS_DIALOG_DOWNLOADING_DESC,
    };

    useEffect(() => {
      const uniquePlatforms = Object.values(selectedDevices).reduce(
        (platforms, device) => {
          if (!platforms.includes(device.platform)) {
            platforms.push(device.platform);
          }
          return platforms;
        },
        []
      );
      setSelectedDevicePlatforms(uniquePlatforms);
    }, [selectedDevices]);

    useEffect(() => {
      if (countriesJointedStr) {
        // When countries list changed
        // compare the devices countries list and remove all not existed countries from the selected list
        // then update selected state
        const arr = [];
        selectedCountries.forEach((country) => {
          if (countries.has(country)) {
            arr.push(country);
          }
        });

        setSelectedCountries(
          arr.length === 0 ? [DeviceStrings.FILTER_ALL] : arr
        );
      }
      // eslint-disable-next-line
    }, [countriesJointedStr]);
    /** -------- */

    /** Reatails filter */
    const retailerKey = "retailerName";
    const selectedAllRetailers =
      selectedRetailers.length === 1 &&
      selectedRetailers[0] === DeviceStrings.FILTER_ALL;

    var retailers = buildFilterItems(devices, retailerKey);
    var retailersItems = [...retailers.values()];

    if (!selectedAllRetailers) {
      devicesFiltered = filterByKey(
        devicesFiltered,
        selectedRetailers,
        retailerKey
      );
    }

    const retailersJointedStr = JSON.stringify(retailersItems);

    useEffect(() => {
      if (retailersJointedStr) {
        // When retailers list changed
        // compare the devices retailers list and remove all not existed retailers from the selected list
        // then update selected state
        const arr = [];
        selectedRetailers.forEach((retailer) => {
          if (retailers.has(retailer)) {
            arr.push(retailer);
          }
        });
        setSelectedRetailers(
          arr.length === 0 ? [DeviceStrings.FILTER_ALL] : arr
        );
      }
      // eslint-disable-next-line
    }, [retailersJointedStr]);
    /** -------- */

    /** Tags filter */
    const tagsKey = "tags";
    const selectedAllTags =
      selectedTags.length === 1 && selectedTags[0] === DeviceStrings.FILTER_ALL;

    // Get tags for dropdown list
    var tags = buildFilterItems(devices, tagsKey);
    var tagsItems = [...tags.values()];

    // Filter devices by tags
    if (!selectedAllTags) {
      devicesFiltered = filterByKey(devicesFiltered, selectedTags, tagsKey);
    }

    const tagsJointedStr = JSON.stringify(tagsItems);

    useEffect(() => {
      if (tagsJointedStr) {
        // When tags list changed (removed tag with count 0 from a device)
        // compare the devices tags list and remove all not existed tags from the selected list
        // then update selected state
        const arr = [];
        selectedTags.forEach((tag) => {
          if (tags.has(tag)) {
            arr.push(tag);
          }
        });

        setSelectedTags(arr.length === 0 ? [DeviceStrings.FILTER_ALL] : arr);
      }
      // eslint-disable-next-line
    }, [tagsJointedStr]);
    /** -------- */

    // update all dropdown items
    countries = buildFilterItems(devicesFiltered, countryKey);
    countriesItems = [...countries.values()];
    retailers = buildFilterItems(devicesFiltered, retailerKey);
    retailersItems = [...retailers.values()];
    tags = buildFilterItems(devicesFiltered, tagsKey);
    tagsItems = [...tags.values()];

    // multiple is just a toggle trigger here
    useEffect(() => {
      setSelectMultiple(!selectMultiple);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiple]);

    // monitor device transferred
    useEffect(() => {
      if (deviceTransferred) {
        setSelectedDevices({});
        setSelectMultiple(false);
        setShowDialog(false);
        enqueueSnackbar(DeviceStrings.TRANSFER_COMPLETED, {
          variant: "success",
        });
      }
    }, [deviceTransferred, enqueueSnackbar]);

    if (isLoading(devices) || isLoading(total)) return <Spinner />;

    const onLoadMore = debounce((size) => {
      if (size >= limit) {
        const max = mobile
          ? DISPLAY_ITEMS.MAX / DISPLAY_ITEMS.MOBILE_DIVIDER
          : DISPLAY_ITEMS.MAX;
        const newLimit = Math.min(
          limit +
          DISPLAY_ITEMS.LOAD_MORE /
          (mobile ? DISPLAY_ITEMS.MOBILE_DIVIDER : 1),
          total,
          max
        );
        if (newLimit > limit) {
          console.debug(`Showing ${newLimit} of ${total} devices`);
          setLimit(newLimit);
        }
      }
    }, 500);

    const onSelectDevice = (device) => {
      const { deviceId } = device;
      setSelectedDevices((prevState) => {
        const state = { ...prevState };
        if (state[deviceId]) {
          delete state[deviceId];
          return state;
        } else {
          return { ...prevState, [deviceId]: device };
        }
      });
    };

    const onSelectAllToggle = () => {
      if (selectedCount > 0) {
        onSelectNone();
      } else {
        onSelectAll();
      }
    };

    const onSelectAll = () => {
      setSelectedDevices(
        Object.fromEntries(devicesFiltered.map((d) => [d.deviceId, d]))
      );
    };

    const onSelectNone = () => {
      setSelectedDevices({});
    };

    const onSelectCancel = () => {
      setSelectMultiple(false);
      onSelectNone();
    };

    // action({ projectId, deviceId })
    const onMultiConfirm = ({ message, action, params }) => {
      const msg = message.replace(
        "{x}",
        selectedCount
      );
      confirm({ message: msg })
        .then(() => {
          action({
            projectId,
            deviceId: selectedDeviceIds,
            v2,
            ...params,
          }).then((result) => {
            if (result.success) {
              enqueueSnackbar(DeviceStrings.REQUEST_SENT, {
                variant: "success",
              });
            } else {
              enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
              console.warn("ccOnline", result.errors[0]);
            }
          });
        })
        .catch(() => { });
    }

    const onMultiOnline = () =>
      onMultiConfirm({
        message: DeviceStrings.MULTI_ONLINE_CONFIRM,
        action: ccOnline,
      });

    const onMultiCompliance = () =>
      onMultiConfirm({
        message: DeviceStrings.MULTI_COMPLIANCE_CONFIRM,
        action: ccCompliance,
      });

    const onMultiDeploy = () =>
      onMultiConfirm({
        message: DeviceStrings.MULTI_DEPLOY_CONFIRM,
        action: ccDeploy,
      });

    const onMultiDecommission = () =>
      onMultiConfirm({
        message: live ? DeviceStrings.MULTI_DECOMMISSION_CONFIRM : DeviceStrings.MULTI_RECOMMISSION_CONFIRM,
        action: decommissionDevice,
        params: {
          decommissioned: live,
        }
      });

    const onChangeCountry = (e) => {
      const selected = e.target.value;
      if (
        selected[selected.length - 1] === DeviceStrings.FILTER_ALL ||
        selected.length === 0
      ) {
        // select only all or select all when unselected all other items
        setSelectedCountries([DeviceStrings.FILTER_ALL]);
      } else if (
        selected[0] === DeviceStrings.FILTER_ALL &&
        selected.length > 1
      ) {
        // clear all
        setSelectedCountries(selected.slice(1));
      } else {
        setSelectedCountries(selected);
      }
    };

    const onChangeRetailer = (e) => {
      const selected = e.target.value;
      if (
        selected[selected.length - 1] === DeviceStrings.FILTER_ALL ||
        selected.length === 0
      ) {
        // select only all or select all when unselected all other items
        setSelectedRetailers([DeviceStrings.FILTER_ALL]);
      } else if (
        selected[0] === DeviceStrings.FILTER_ALL &&
        selected.length > 1
      ) {
        // clear all
        setSelectedRetailers(selected.slice(1));
      } else {
        setSelectedRetailers(selected);
      }
    };

    const onChangeTag = (e) => {
      const selected = e.target.value;
      if (
        selected[selected.length - 1] === DeviceStrings.FILTER_ALL ||
        selected.length === 0
      ) {
        // select only all or select all when unselected all other items
        setSelectedTags([DeviceStrings.FILTER_ALL]);
      } else if (
        selected[0] === DeviceStrings.FILTER_ALL &&
        selected.length > 1
      ) {
        // clear all
        setSelectedTags(selected.slice(1));
      } else {
        setSelectedTags(selected);
      }
    };

    const onRenderCountries = (s) => {
      if (s[0] === DeviceStrings.FILTER_ALL) return DeviceStrings.FILTER_ALL;
      return `${s.length} countr${s.length <= 1 ? "y" : "ies"}`;
    };

    const onRenderRetailers = (s) => {
      if (s[0] === DeviceStrings.FILTER_ALL) return DeviceStrings.FILTER_ALL;
      return `${s.length} retailer${s.length <= 1 ? "" : "s"}`;
    };

    const onRenderTags = (s) => {
      if (s[0] === DeviceStrings.FILTER_ALL) return DeviceStrings.FILTER_ALL;
      return `${s.length} tag${s.length <= 1 ? "" : "s"}`;
    };

    const onClickClear = () => {
      setSelectedCountries([DeviceStrings.FILTER_ALL]);
      setSelectedRetailers([DeviceStrings.FILTER_ALL]);
      setSelectedTags([DeviceStrings.FILTER_ALL]);
    };

    const onOpenCustomQR = async () => {
      const options = Object.values(selectedDevices)
        .map((device) => {
          const { deviceId } = device;
          const isQrId = device?.platform === DEVICE_PLATFORMS.QRID;

          if (!isQrId) return null;

          return {
            url: getLinkUrl({ linkId: deviceId }),
            filename: deviceId,
          };
        })
        .filter((d) => d);

      if (!options.length) {
        enqueueSnackbar(DeviceStrings.CREATE_SMART_TAGS_NO_SMART_TAGS, {
          variant: "warning",
        });
        return;
      }
      const qrSetup = await getProjectQRSetup(projectId);

      setMediaQRSettings({ options, qrSetup });
      setShowCustomQRDialog(true);
    };

    const onOkQRHandle = () => {
      setShowCustomQRDialog(false);
    };

    const onCloseQRHandle = () => {
      setShowCustomQRDialog(false);
      setMediaQRSettings(null);
    };

    const onUpdateQRHandle = async (qrSettings) => {
      const { qrSetup, image } = qrSettings;
      let data = {
        projectId,
        qrSetup,
      };

      const { file, action, ...restImage } = image;
      const imageStoragePath = `projects/${projectId}/devicesQRImage`;

      if (action === "upload") {
        const url = await uploadFile({ file, path: imageStoragePath });
        delete restImage.action;
        data = {
          ...data,
          qrSetup: { ...qrSetup, image: { ...restImage, url } },
        };
      } else if (action === "remove") {
        const { action, url, fileType, ...restImage } = image;
        await deleteFile({ path: imageStoragePath });
        data = { ...data, qrSetup: { ...qrSetup, image: { ...restImage } } };
      }

      await updateQRSetup(data);
      setMediaQRSettings(null);
    };

    const selectContent = selectMultiple && (
      <div className={classes.multiRow}>
        <Button className={classes.multiButton} onClick={onSelectAllToggle}>
          {selectedCount > 0
            ? DeviceStrings.MULTI_SELECT_NONE
            : DeviceStrings.MULTI_SELECT_ALL}
        </Button>

        <Button className={classes.multiButton} onClick={onSelectCancel}>
          {DeviceStrings.MULTI_SELECT_CANCEL}
        </Button>
      </div>
    );

    const actionContent = selectMultiple && (
      <div className={classes.multiRow}>
        <Button
          className={classes.multiButton}
          disabled={
            actionsDisabled ||
            !canWriteDevices ||
            selectedDevicePlatforms.includes(DEVICE_PLATFORMS.QRID)
          }
          onClick={onMultiOnline}
        >
          {DeviceStrings.ONLINE_BUTTON}
        </Button>
        <Button
          className={classes.multiButton}
          disabled={
            actionsDisabled ||
            !canWriteDevices ||
            selectedDevicePlatforms.includes(DEVICE_PLATFORMS.QRID)
          }
          onClick={onMultiCompliance}
        >
          {DeviceStrings.COMPLIANCE_BUTTON}
        </Button>
        <Button
          className={classes.multiButton}
          disabled={
            actionsDisabled ||
            !canWriteDevices ||
            selectedDevicePlatforms.includes(DEVICE_PLATFORMS.QRID)
          }
          onClick={onMultiDeploy}
        >
          {DeviceStrings.DEPLOY_BUTTON}
        </Button>
        <Button
          className={classes.multiButton}
          disabled={
            actionsDisabled ||
            !canWriteDevices
          }
          onClick={onMultiDecommission}
        >
          {live ? DeviceStrings.DECOMMISSION_BUTTON : DeviceStrings.RECOMMISSION_BUTTON}
        </Button>
        <Button
          className={classes.multiButton}
          disabled={actionsDisabled || !canWriteDeviceExtra}
          onClick={() => {
            setShowTagDialog(true);
          }}
        >
          {DeviceStrings.TAG_BUTTON}
        </Button>
        <Button
          className={classes.multiButton}
          disabled={actionsDisabled || !canWriteDevices}
          onClick={() => {
            setShowDialog(true);
          }}
        >
          {DeviceStrings.TRANSFER_BUTTON}
        </Button>
        <Button
          className={classes.multiButton}
          disabled={actionsDisabled || !canWriteDeviceExtra}
          onClick={onOpenCustomQR}
        >
          {DeviceStrings.CREATE_SMART_TAGS_BUTTON}
        </Button>
      </div>
    );

    const filterContent = (
      <div className={classes.filter}>
        <Dropdown
          label={DeviceStrings.FILTER_TAG}
          items={tagsItems}
          selected={selectedTags}
          onRenderValue={onRenderTags}
          onChange={onChangeTag}
        />
        <Dropdown
          label={DeviceStrings.FILTER_COUNTRY}
          items={countriesItems}
          selected={selectedCountries}
          onRenderValue={onRenderCountries}
          onChange={onChangeCountry}
        />
        <Dropdown
          label={DeviceStrings.FILTER_RETAILER}
          items={retailersItems}
          selected={selectedRetailers}
          onRenderValue={onRenderRetailers}
          onChange={onChangeRetailer}
        />
        <Button onClick={onClickClear}>Clear</Button>
      </div>
    );

    const configDialog = {
      onClose: () => {
        setShowDialog(false);
      },
      large: true,
    };

    return total > 0 ? (
      <>
        {showTagDialog && (
          <DeviceTagsCard
            projectId={projectId}
            deviceId={selectedDeviceIds}
            canRead={permissions?.canReadDeviceExtra}
            canEdit={permissions?.canWriteDeviceExtra}
            variant={"no-card"}
            show={showTagDialog}
            setShow={setShowTagDialog}
          />
        )}
        {filterContent}
        {selectContent}
        {actionContent}
        <List
          devices={devicesFiltered}
          max={total}
          onLoadMore={onLoadMore}
          onClickDevice={onClickDevice}
          onSelectDevice={onSelectDevice}
          selectedDevices={selectedDevices}
          multiple={selectMultiple}
          projectId={projectId}
          v2={v2}
        />
        <MyDialog open={showDialog} config={configDialog}>
          <div
            style={{
              height: 600, // in order to stretch MyDialog
            }}
          >
            <DeviceTransfer
              devices={selectedDevices}
              projectId={projectId}
              permissions={permissions}
              active={true}
              dismiss={deviceTransferred}
              v2={v2}
            />
          </div>
        </MyDialog>
        {mediaQRSettings && (
          <CustomQRCodeDialog
            open={showCustomQRDialog}
            title={DeviceStrings.CREATE_SMART_TAGS_DIALOG_TITLE}
            settings={mediaQRSettings}
            contentSettings={qrConfig}
            urlType="smartTag"
            folderName={`project-${projectId}-qr`}
            onOk={onOkQRHandle}
            onClose={onCloseQRHandle}
            onUpdate={onUpdateQRHandle}
          />
        )}
      </>
    ) : live ? (
      <EmptyData
        title={DeviceStrings.NO_DEVICES_TITLE}
        description={DeviceStrings.NO_DEVICES_TITLE_DESC}
        // TODO: add an action to add a new device "actionTitle", "onClick"
        icon={<DevicesIcon />}
      />
    ) : (
      <></>
    );
  },
  isDeviceListEqual
);

export default DeviceList;
