import React, { memo, useEffect, useRef, useState } from "react";
import { addMediaLinkParams } from "../../services/MediaService";
import { getProjectMediaUrl } from "../../services/ProjectService";
import { getItemLocalStorage } from "../../services/LocalStorageService";
import { updateShowroomLinkVisists, createProjectDeviceHeartbeat, getShowroomLink } from "services/ApiService";
// Media frame for public preview
// Using link to access
// memo prevents component rerendering when link and ref don't change
// We don't want the component to render multiple times because each time the iframe is rendered it will count as a google analytics session
export const PublicMediaFrame = memo(
  React.forwardRef(({ mediaLink }, ref) => {
    const [link, setLink] = useState();
    const linkId = mediaLink?.linkId;
    const projectId = mediaLink?.projectId;

    const onLoadIframeHandle = async () => {
      if (mediaLink.deviceId) {
        const deviceId = mediaLink.deviceId;
        createProjectDeviceHeartbeat({ deviceId });
      }
      const gauid = getItemLocalStorage({ key: "gauid" });
      await updateShowroomLinkVisists({
        gauid,
        projectId,
        linkId,
      });
    };

    useEffect(() => {
      getShowroomLink({ linkId }).then((info) => {
        // add timestamp to prevent caching issues
        // adding timestamp inside component so the iframe doesn't rerender
        setLink(addMediaLinkParams(info.result.link));
      });
    }, [linkId]);

    if (!link) return <></>;

    return (
      <iframe
        ref={ref}
        title="media"
        src={link}
        onLoad={onLoadIframeHandle}
        width="100%"
        height="100%"
        allow="web-share"
        scrolling="auto"
        frameBorder="0"
      />
    );
  })
);

// Media frame for user
// Using projectId to access
export const MediaFrame = memo(
  ({ projectId, updated, draftId, liveDraftId }) => {
    const [mediaUrl, setMediaUrl] = useState();
    const mounted = useRef(false);

    useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      };
    }, []);

    // draftId is null for live
    // therefore if the live content has been changed (ie. published) then we cannot tell only from draftId
    // liveDraftId may be null for legacy content but once published it will exist
    useEffect(() => {
      if (!projectId) return;
      getProjectMediaUrl({
        projectId,
        draftId,
        appendTimestamp: true,
      }).then((res) => {
        if (mounted.current) setMediaUrl(res);
      });
    }, [projectId, draftId, liveDraftId]);

    return (
      <iframe
        title="media"
        src={mediaUrl}
        width="100%"
        height="100%"
        scrolling="auto"
      />
    );
  }
);
