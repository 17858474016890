import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useRelativeTimeString } from "../../utils/localeUtils";
import {
  useDeviceDeploymentState,
  DEPLOYMENT_STATUSES,
  TASK_TYPES,
} from "../../services/ProjectService";
import { useMobileLayout } from "hooks/uiHooks";

import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

import {
  // DeployStrings,
  DeviceStrings,
} from "../../strings";
import { isLoading } from "utils/uiUtils";

const MAX_DISPLAY_WORKING = 5;

const useStyles = makeStyles((theme) => {
  return {
    title: {
      display: "flex",
      alignItems: "center",
    },
    content: {
      padding: 0,
    },
    row: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
    progress: {
      marginBottom: theme.spacing(3),
    },
    caption: {
      fontWeight: "bold",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    taskMain: {
      padding: theme.spacing(1),
    },
    accordionMain: {
      backgroundColor: theme.palette.background.default,
    },
    accordionSummary: {
      width: "100%",
    },
    taskRow: {
      gap: theme.spacing(0.5),
    },
  };
});

const statusToString = (status, checkComplianceOnly) => {
  var strings = DeviceStrings.DEPLOY_PROGRESS_STATUS;
  if (checkComplianceOnly) {
    strings = DeviceStrings.COMPLIANCE_CHECK_PROGRESS_STATUS;
  }
  switch (status) {
    case DEPLOYMENT_STATUSES.COMPLETED:
      return strings.COMPLETED;
    case DEPLOYMENT_STATUSES.FAILED:
      return strings.FAILED;
    case DEPLOYMENT_STATUSES.WORKING:
      return strings.WORKING;
    case DEPLOYMENT_STATUSES.PENDING:
      return strings.PENDING;
    case DEPLOYMENT_STATUSES.NOT_STARTED:
    default:
      return strings.NOT_STARTED;
  }
};

const typeToString = (type, checkComplianceOnly) => {
  var strings = DeviceStrings.DEPLOY_TASK_TYPE;
  if (checkComplianceOnly) {
    strings = DeviceStrings.COMPLIANCE_CHECK_TYPE;
  }
  switch (type) {
    case TASK_TYPES.COPY_APP_FILE_TO_EXTERNAL:
      return strings.COPY_APP_FILE_TO_EXTERNAL;
    case TASK_TYPES.COPY_APP_FILE_TO_SHARED:
      return strings.COPY_APP_FILE_TO_SHARED;
    case TASK_TYPES.DOWNLOAD_CUSTOM_FILE:
      return strings.DOWNLOAD_CUSTOM_FILE;
    case TASK_TYPES.DOWNLOAD_MEDIA_FILE:
      return strings.DOWNLOAD_MEDIA_FILE;
    case TASK_TYPES.EXECUTE_COMMAND:
      return strings.EXECUTE_COMMAND;
    case TASK_TYPES.INSTALL_APPLICATION:
      return strings.INSTALL_APPLICATION;
    case TASK_TYPES.SETUP_BOOT_TASKS:
      return strings.SETUP_BOOT_TASKS;
    case TASK_TYPES.EXECUTE_INSTALL_TASK:
      return strings.EXECUTE_INSTALL_TASK;
    case TASK_TYPES.RECOVERY_CODE_REQUIRED:
      return strings.RECOVERY_CODE_REQUIRED;
    default:
      return strings.UNKNOWN;
  }
};

const ProgressBar = ({ loading, error = false, disabled }) => {
  if (disabled) return <></>;
  return error || !loading ? (
    <LinearProgress
      variant="determinate"
      value={100}
      color={error ? "secondary" : "primary"}
    />
  ) : (
    <LinearProgress />
  );
};

const TaskRow = ({ label, list }) => {
  const classes = useStyles();
  if (!list || list.length === 0) return <></>;
  const showMaxItems = list?.length > MAX_DISPLAY_WORKING;
  return (
    <div className={`${classes.title} ${classes.taskRow}`}>
      {`${label}: `}
      {showMaxItems
        ? `${list?.length} items`
        : list?.map((t, i) => (
          <div key={`${label}-${i}`} className={classes.title}>
            <span>{t.title}</span>
            {t.error && (
              <Tooltip title={t.error} arrow>
                <InfoOutlinedIcon className={classes.info} />
              </Tooltip>
            )}
            <span>{i < list.length - 1 && ", "}</span>
          </div>
        ))}
    </div>
  );
};

// for each type (stage) of task
const TaskType = ({ type, data, checkComplianceOnly }) => {
  const classes = useStyles();
  const [defaultExpanded] = useState(
    data?.failed?.length > 0 ||
    data?.working?.length > 0 ||
    data?.pending?.length > 0
  );
  const skipped = checkComplianceOnly && data?.skipComplianceCheck;
  if (skipped) return <></>;
  const notStarted = data?.status === null;
  const error =
    data?.failed?.length > 0 || data?.status === DEPLOYMENT_STATUSES.FAILED;
  const loading =
    data?.total > 0 &&
    (!data?.completed?.length || data?.completed?.length < data?.total);
  const title = `${typeToString(type, checkComplianceOnly)}: [${data?.completed?.length ?? 0
    }/${data?.total}]${skipped ? " (" + DeviceStrings.COMPLIANCE_CHECK_SKIP + ")" : ""
    }`;
  return (
    <div className={`${classes.flexColumn} ${classes.taskMain}`}>
      <Accordion
        className={classes.accordionMain}
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
          <div className={`${classes.flexColumn} ${classes.accordionSummary}`}>
            <span>{title}</span>
            <ProgressBar
              loading={loading}
              error={error}
              disabled={notStarted}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.flexColumn}>
          {data?.completed && (
            <TaskRow
              label={
                checkComplianceOnly
                  ? DeviceStrings.DEPLOY_STATUS_PASSED
                  : DeviceStrings.DEPLOY_STATUS_COMPLETED
              }
              list={data?.completed}
            />
          )}
          {data?.failed && (
            <TaskRow
              label={DeviceStrings.DEPLOY_STATUS_FAILED}
              list={data?.failed}
            />
          )}
          {data?.working && (
            <TaskRow
              label={DeviceStrings.DEPLOY_STATUS_WORKING}
              list={data?.working}
            />
          )}
          {data?.pending && (
            <TaskRow
              label={DeviceStrings.DEPLOY_STATUS_PENDING}
              list={data?.pending}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const DeploymentDialog = ({
  deviceId,
  projectId,
  open,
  onClose,
  checkCompliance = false,
}) => {
  const classes = useStyles();
  const mobile = useMobileLayout();

  const state = useDeviceDeploymentState(projectId, deviceId, checkCompliance);
  const loading =
    state?.status === DEPLOYMENT_STATUSES.WORKING ||
    state?.status === DEPLOYMENT_STATUSES.PENDING;
  const error = state?.status === DEPLOYMENT_STATUSES.FAILED;
  const startedTime = useRelativeTimeString(state?.timestamp);
  // no need to show some types for compliance check
  const taskTypesToExclude = checkCompliance
    ? [
      TASK_TYPES.COPY_APP_FILE_TO_EXTERNAL,
      TASK_TYPES.COPY_APP_FILE_TO_SHARED,
      TASK_TYPES.EXECUTE_COMMAND,
    ]
    : [];

  const loadingState = isLoading(state);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} fullScreen={mobile}>
      <DialogTitle className={classes.title} disableTypography onClick={onClose}>
        <Box pr={2}>
          <CloudDownloadOutlinedIcon />
        </Box>
        <Typography variant="caption">
          {checkCompliance
            ? DeviceStrings.COMPLIANCE_CHECK_PROGRESS_TITLE
            : DeviceStrings.DEPLOY_PROGRESS_TITLE}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          {loadingState ? "Loading..." : statusToString(state?.status, checkCompliance)}
          {startedTime && ` (${startedTime})`}
        </div>
        <div className={classes.progress}>
          <ProgressBar loading={loading} error={error} />
        </div>

        {state?.types &&
          Object.entries(state?.types)
            .filter(([k, v]) => !taskTypesToExclude.includes(k))
            .map(([type, data], i) => (
              <TaskType
                key={`type-${i}`}
                type={type}
                data={data}
                checkComplianceOnly={checkCompliance}
              />
            ))
        }
      </DialogContent>
    </Dialog>
  );
};

export default DeploymentDialog;
