import React, { useCallback, useState } from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { debounce } from "../../services/UiService";
import { ccScreencap, ccBackspace, ccText } from "../../services/DeviceService";

import ButtonCard from "../cards/ButtonCard";
import RemoteLog from "../remote/RemoteLog";

import TextFieldsOutlinedIcon from "@material-ui/icons/TextFieldsOutlined";

import { DeviceStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  inputMain: {
    padding: 24,
    height: "100%",
  },
  inputText: {
    position: "relative",
  },
  inputBackspace: ({ disabled }) => ({
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    padding: 16,
    display: "flex",
    cursor: disabled ? "auto" : "pointer",
  }),
}));

const RemotePanelInput = ({
  projectId,
  deviceId,
  disabled,
  onStart,
  onComplete,
}) => {
  const classes = useStyles({ disabled });

  const [keyboardInput, setKeyboardInput] = useState("");

  const refresh = () =>
    sendRemoteCommand({
      ccAction: ccScreencap,
    });

  const sendRemoteCommand = (state) => {
    if (disabled) return;
    onStart();
    const { ccAction, refreshOnComplete, input } = state;
    ccAction({ projectId, deviceId, input })
      .then(() => {
        setKeyboardInput("");
        if (refreshOnComplete) refresh();
      })
      .catch((err) => {
        console.error(`Action FAIL`, err);
      })
      .finally(() => {
        if (!refreshOnComplete) onComplete();
      });
  };

  // debounce won't work without useCallback()
  const onText = useCallback(
    debounce((value) => {
      sendRemoteCommand({
        ccAction: ccText,
        input: {
          text: value,
        },
        refreshOnComplete: true,
      })
    }, 1000),
    []
  );
  const onChangeInput = (e) => {
    const text = e.target.value;
    setKeyboardInput(text);
    onText(text);
  };

  const onBackspace = () => {
    sendRemoteCommand({
      ccAction: ccBackspace,
      refreshOnComplete: true,
    });
  };

  return (
    <div className={classes.inputMain}>
      <ButtonCard
        config={{
          icon: <TextFieldsOutlinedIcon />,
          title: DeviceStrings.REMOTE_PANEL_INPUT_TITLE,
          fullHeight: true,
          desc: DeviceStrings.REMOTE_PANEL_INPUT_DESC,
          sidePanel: <RemoteLog deviceId={deviceId} />,
        }}
        canRead={true}
      >
        <div className={classes.inputText}>
          <TextField
            fullWidth
            disabled={disabled}
            label={DeviceStrings.REMOTE_PANEL_INPUT_TEXT_LABEL}
            value={keyboardInput}
            onChange={onChangeInput}
          />
          <div className={classes.inputBackspace} onClick={onBackspace}>
            <span className="material-symbols-outlined">backspace</span>
          </div>
        </div>
      </ButtonCard>
    </div>
  );
};

export default RemotePanelInput;
