import React, { useLayoutEffect, useRef, useState } from "react";
import { Radio } from "@material-ui/core";
import { useSnackbar } from "notistack";

import {
  uploadProjectMediaFile,
  deleteProjectMediaFiles,
  createProjectDraftId,
  getProjectDraftPath,
} from "../services/ProjectService";
import { getFileListSize, getFileName, getFilePath } from "../utils/fileUtils";
import { useProjectContentPermission } from "../services/PermissionService";
import { createMediaDraft } from "../services/ApiService";

import ProgressDialog from "./dialogs/ProgressDialog";
import MyDialog from "./MyDialog";

import { DefaultStrings, ProjectStrings } from "../strings";

// upload options no need to be reactive
const uploadOpts = {
  total: null,
  size: null,
  files: null,
  name: null,
};

/**
 * A component to upload project draft to storage
 * open = show select folder dialog
 * onFinish = callback when upload finish
 * firstUpload = start upload without confirmation dialog
 */
const UploadProjectDraft = ({
  open,
  onFinish,
  projectId,
  draftName,
  firstUpload = true,
}) => {
  const permission = useProjectContentPermission(projectId);
  const canWrite = permission?.read && permission?.write;

  // only read info if permission allows
  const fileInput = useRef(null);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const [deleting, setDeleting] = useState();
  const [uploadIndex, setUploadIndex] = useState(0);
  const [uploadCurrentFile, setUploadCurrentFile] = useState();
  const [showing, setShowing] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [draft, setDraft] = useState();
  const { enqueueSnackbar } = useSnackbar();

  // monitor if we should start uploading process
  useLayoutEffect(() => {
    if (!open || !canWrite) return;

    fileInput.current.focus();
    fileInput.current.click();

    setUploading(true);
    setDraft({
      projectId,
      draftName: draftName ?? createProjectDraftId(projectId),
      firstUpload,
    });
  }, [open, canWrite, projectId, draftName, firstUpload]);

  const onInputFocus = () => {
    if (uploading && showing) {
      setUploading(false);
      setShowing(false);
      if (onFinish) onFinish();
    }
  };
  const onInputBlur = () => {
    if (uploading) setShowing(true);
  };

  const onSelectFolder = async (e) => {
    const files = e.target.files;
    const count = files.length;
    const size = getFileListSize(files);

    if (count === 0) return;

    uploadOpts.total = count;
    uploadOpts.size = size;
    uploadOpts.files = files;
    const path = files[0].webkitRelativePath;
    uploadOpts.name = path.slice(0, path.indexOf("/"));
    setUploadIndex(0);

    // start upload directly if first time
    if (draft.firstUpload) startUpload();
    // otherwise show confirm dialog
    else setShowUploadDialog(true);
  };

  const onStartUpload = (cleanUpload) => {
    if (cleanUpload) {
      // set progress
      setDeleting(true);
      setShowProgressDialog(true);

      // delete files first
      deleteProjectMediaFiles({
        projectId: draft.projectId,
        draftName: draft.draftName,
      })
        .then(() => {
          startUpload();
        })
        .catch((error) => {
          console.warn(error);
        });
    } else {
      startUpload();
    }
  };

  const startUpload = async () => {
    // set progress
    setDeleting(false);
    setShowProgressDialog(true);

    const basePath = await getProjectDraftPath(draft.projectId, draft.draftName);
    console.debug("startUpload", basePath, uploadOpts);

    for (let i = 0; i < uploadOpts.total; i++) {
      const file = uploadOpts.files[i];
      const path = getFilePath(file);
      const name = getFileName(file);
      // setUploadCurrentFile(`${path}/${name}`);
      setUploadCurrentFile(name);
      setUploadIndex(i + 1);
      await uploadProjectMediaFile({
        basePath,
        file: uploadOpts.files[i],
        path,
      });
    }

    setShowProgressDialog(false);

    const msg = draft.firstUpload
      ? ProjectStrings.MEDIA_DRAFT_CREATE_MSG
      : ProjectStrings.MEDIA_DRAFT_UPDATE_MSG;

    // notify project update, so other parts can be refreshed:
    // 1 - MediaCard
    // 2 - Media info
    createMediaDraft({
      projectId: draft.projectId,
      draftName: draft.draftName,
      name: uploadOpts.name,
      message: msg.replace("{draft}", uploadOpts.name),
    })
      .then(() => {
        enqueueSnackbar(
          draft.firstUpload
            ? ProjectStrings.MEDIA_DRAFT_CREATE_OK
            : ProjectStrings.MEDIA_DRAFT_UPDATE_OK,
          { variant: "success" }
        );
      })
      .catch((err) => {
        console.warn(err);
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
      });
  };

  const UploadDialog = ({ open }) => {
    const [cleanUpload, setCleanUpload] = useState(false);
    const config = {
      title: ProjectStrings.MEDIA_UPLOAD_CONFIRM,
      onClose: () => {
        setShowUploadDialog(false);
      },
      onOk: () => {
        setShowUploadDialog(false);
        onStartUpload(cleanUpload);
      },
    };

    return (
      <MyDialog open={open} config={config}>
        <Radio
          checked={cleanUpload}
          color="primary"
          onClick={(e) => setCleanUpload(!cleanUpload)}
        />
        {ProjectStrings.MEDIA_UPLOAD_CLEAN}
      </MyDialog>
    );
  };

  const title = deleting
    ? ProjectStrings.MEDIA_DELETING
    : ProjectStrings.MEDIA_UPLOADING_TITLE;
  const current = !deleting ? uploadIndex : undefined;
  const total = !deleting ? uploadOpts.total : undefined;

  return (
    <>
      <UploadDialog open={showUploadDialog} />
      <ProgressDialog
        open={showProgressDialog}
        config={{
          title,
          current,
          total,
          desc: uploadCurrentFile,
        }}
      />
      <input
        type="file"
        id="contentPath"
        ref={fileInput}
        directory=""
        webkitdirectory=""
        onChange={onSelectFolder}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 0,
        }}
      />
    </>
  );
};

export default UploadProjectDraft;
